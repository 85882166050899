/**
 * Created by shiva on 2/24/19.
 */

import React from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import Login from "./login_form";
import {fetchOrgs} from "../../actions/org_actions";
import QrCode from "./qrcode";
import SecondFactorForm from "./second_factor_form";
const UID_PWD = 'UserIdPwdPage';
const SECOND_FACTOR = 'SecondFactor';
const QR_CODE = 'qrcode';

class Authenticate extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            stepNo: UID_PWD,
            token: null
        };

        this.pwdAuthenticated = this.pwdAuthenticated.bind(this);
        this.authenticateSecondFactorPage = this.authenticateSecondFactorPage.bind(this);
        // this.onSubmit = this.onSubmit.bind(this);
        this.renderStepContent = this.renderStepContent.bind(this);
    }

    componentDidMount() {
    }

    // onSubmit(values) {
    //     this.props.createOrgContract(values, () => {
    //         this.props.history.push("/org/dash/contract");
    //     });
    // }

    pwdAuthenticated(userObject) {
        if (userObject.secondFactorAuthenticated){
            console.log('Pushing to main');
            this.props.history.push('/');
        } else if (userObject.qrCodeUrl) {
            // go to qr code page
            this.setState({
                stepNo: QR_CODE,
                qrCodeUrl: userObject.qrCodeUrl,
                token: userObject.token
            })
        } else {
            // go to second factor auth
            this.setState({stepNo: SECOND_FACTOR, token: userObject.token})
        }
    }

    authenticateSecondFactorPage() {
        this.setState({stepNo: SECOND_FACTOR})
    }

    renderStepContent() {

        switch (this.state.stepNo) {
            case UID_PWD:
                return <Login onSuccess={this.pwdAuthenticated}/>;
            case QR_CODE:
                return <QrCode onContinue={this.authenticateSecondFactorPage} qrCodeUrl={this.state.qrCodeUrl}/>;
            case SECOND_FACTOR:
                return <SecondFactorForm token={this.state.token} onSuccess={this.pwdAuthenticated}/>
        }
    }

    render() {
        return (
            <div>
                {this.renderStepContent()}
            </div>
        )
    }
}

export default reduxForm({
    form: 'AuthenticateForm'
})(
    connect(null, {fetchOrgs})(Authenticate)
);