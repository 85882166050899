/**
 * Created by pshivaraman on 9/13/18.
 */

import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {fetchUsersNotInRole, updateUserRoleForOrg} from "../../actions/user_actions";
import CreateUserModal from "./create_user_modal";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import ListGroup from "react-bootstrap/ListGroup";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

class AssignUserOrgAdminModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.fetchUsers = this.fetchUsers.bind(this);

        this.state = {
            showModal: false,
            name: ""
        };
    }

    open() {
        this.setState({
            showModal: true,
            name: ""
        });
        this.fetchUsers();
    }

    onNameChange() {
        // TODO: Implement
    }

    close() {
        this.setState({
            showModal: false,
            name: ""
        });
        this.props.onFinish();
    }

    onAssign(userId) {
        this.props.updateUserRoleForOrg(userId, this.props.managingOrgId, "ORG_ADMIN", () => {
            this.fetchUsers();
        });
    }

    fetchUsers() {
        this.props.fetchUsersNotInRole("ORG", this.props.managingOrgId, "ORG_ADMIN", true);
    }

    getResult() {
        const {users} = this.props;
        const userItems = users.map(user => {
            return (
                <ListGroupItem key={user.id}>
                    {user.userName} <Button onClick={() => this.onAssign(user.id)}>Assign</Button>
                </ListGroupItem>
            )
        });
        return (
            <ListGroup>
                {userItems}
            </ListGroup>
        );
    }

    render() {
        const {showModal} = this.state;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>Assign Organization Admins</Button>
                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Organization Administrators</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form.Group>
                            <Form.Label>Name</Form.Label>
                            <Form.Control type="text" placeholder="Type to begin searching..." value={this.state.name}
                                         onChange={this.onNameChange.bind(this)} disabled/>
                        </Form.Group>

                        {this.getResult()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Finished</Button>
                        <CreateUserModal onCreate={this.fetchUsers} sysAdminUserCreation={false}/>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

function mapStateToProps({users}) {
    return {
        users
    }
}

export default connect(mapStateToProps, {fetchUsersNotInRole, updateUserRoleForOrg})(AssignUserOrgAdminModal)