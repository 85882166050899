/**
 * Created by shivaraman on 2/23/19.
 */
import React from 'react';
import {change, Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import {authUserForSecondFactor} from '../../actions/auth_actions';
import {fetchOrgs} from "../../actions/org_actions";
import {FieldRBInputSrOnly} from "../../components/redux_form/field_components_rb";
import PropTypes from "prop-types";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Alert from "react-bootstrap/Alert";
class SecondFactorAuth extends React.Component {

    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        values.token = this.props.token;
        if (!values.secondFactorAuthCode) {
            throw new SubmissionError({
                _error: 'Second factor authentication code is required',
                secondFactorAuthCode: 'Enter second factor authentication code'
            });
        }
        return this.props.authUserForSecondFactor(values, this.props.onSuccess, () => {
            this.props.dispatch(change('SecondFactorAuthForm', 'secondFactorAuthCode', ''));// This is a redux-form function that clears form values.
            // Redux-form async validation error
            throw new SubmissionError({
                _error: 'Invalid two factor code !',
                secondFactorAuthCode: 'Second factor code incorrect'
            });
        });
    }

    render() {
        const {handleSubmit, error} = this.props; // is the same as const handleSubmit=this.props.handleSubmit
        return (
            <div className='reporting_bg p-0'>
                <div className='col-lg-12 row m-0 p-0 h-100 align-items-center'>
                   

                    
                    <form className="form-signin col-sm-12 col-lg-3 col-md-6" onSubmit={handleSubmit(this.onSubmit.bind(this))}>

                        <div className='heading_container'>
                            <h3 style={{background: "transparent"}}>Two-Factor Authentication</h3>
                             {/* <h2 className="form-signin-heading">Two-Factor Authentication</h2> */}
                        </div>

                   
                    {error && <Alert variant="danger" className='alert-danger'>{error}</Alert>}
                    <div className='col-lg-12'>
                        <div className='row mb-4'>
                        <label className='form-label fs-6 fw-bolder text-gray-900'>Two-factor Authentication code</label>
                        <Field name="secondFactorAuthCode" 
                           component={FieldRBInputSrOnly} type="text" required
                           autoFocus/>
                        </div>
                    </div>
                    
                    <Form.Text className="text-muted mb-3">
                        Please enter your code from your mobile app. If you have lost your mobile device enter one of
                        your recovery codes
                    </Form.Text>

                    <Button variant="primary" size="lg" block type="submit" className='w-100'>Verify code</Button>
                </form>
                       
                </div>
             
            </div>
        );
    }
}

function validate(values) {
    const errors = {}

    return errors;
}

SecondFactorAuth.propTypes = {
    onSuccess: PropTypes.func.isRequired,
    token: PropTypes.string.isRequired
};

export default reduxForm({
    validate, // same as validate:validate
    form: 'SecondFactorAuthForm',
    initialValues: {
        secondFactorAuthCode: '',
    }
})(
    connect(null, {authUserForSecondFactor, fetchOrgs})(SecondFactorAuth)
);