/**
 * Created by pshivaraman on 7/15/19.
 */

import React, {Component, Fragment} from "react";
import {getEmployeeTypes} from "../../actions/fieldValue_actions";
import {fetchPersonAsync, updateOrgSubject} from "../../actions/subject_actions";
import {CUR_ORG} from "../../actions/session_constants";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {Card, Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";
import {FormikDatePickerComponent} from "../formik/formik_date_picker";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {FormikFieldDualListBox} from "../formik/formik_field_duallistbox";

class SubjectOrgEditModal extends Component {
    constructor(props){
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false,
            avail_employee_types: []
        }
    }

    open(){
        this.fetchSubject();
    }

    fetchSubject = async () => {
        const {subjectId} = this.props;
        const subject = await fetchPersonAsync(subjectId);
        let initialValues = {
            "title": "",
            "employeeTypes": "",
            "consultantCompany": "",
            "joinDate": ""
        };
        if (subject) {
            const rel = subject.orgs.find((rel) => rel.orgId === sessionStorage.getItem(CUR_ORG) && rel.active);
            if (rel) {
                initialValues.title = rel.title;
                console.log('Employee types', rel);
                if (rel.employeeTypes) {
                    initialValues.employeeTypes = rel.employeeTypes.map((type) => type.id);
                }
            }
        }
        this.setState({
            showModal: true,
            initialValues,
            subject
        });
        getEmployeeTypes().then((response) => {
            this.setState({
                avail_employee_types: response.data.map((empType) => {
                    return {
                        value: empType.id,
                        label: empType.name
                    }
                })
            });
        });

    }

    close = () =>{
        this.props.onFinish();
        this.setState({
            showModal: false
        });
    }

    onSubmit = (values) => {
        const {subjectId, onFinish} = this.props;
        // Create relationship
        const subjectOrgRel = {
            orgId: sessionStorage.getItem(CUR_ORG),
            title: values.title,
            employeeTypes: values.employeeTypes,
            consultantCompany: values.consultantCompany?values.consultantCompany:null,
            joinDate: values.joinDate
        };
        // Send data to server
        this.props.updateOrgSubject(subjectId, subjectOrgRel, () => {
            this.close();
        });
    }

    render(){
        const {showModal, avail_employee_types, initialValues} = this.state;
        const {handleSubmit} = this.props;

        return (
            <Fragment>
                {ConditionalDisplay(
                    <Button variant="primary" onClick={this.open}>Edit</Button>
                )}
                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Edit Subject-Organization Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Formik
                            initialValues={initialValues}
                            enableReinitialize={true}
                            onSubmit={this.onSubmit}
                            validationSchema={
                                Yup.object().shape({
                                    joinDate: Yup.date().required('Please enter an Effective Date')
                                })
                            }
                        >
                            {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, errors, handleBlur}) => (
                                <Form noValidate onSubmit={handleSubmit}>
                                    <Card>
                                        <Card.Header>Organization Details</Card.Header>
                                        <Card.Body>
                                            <Form.Row>
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label>Job Title</Form.Label>
                                                    <Form.Control required type="text" name={'title'}
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur} placeholder={'Job Title'}
                                                                  isInvalid={touched.title && !!errors.title}
                                                                  value={values.title}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.title}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="12">
                                                    <Form.Label>Employee Types</Form.Label>
                                                    <Field
                                                        id="employeeTypes"
                                                        name="employeeTypes"
                                                        component={FormikFieldDualListBox}
                                                        options={avail_employee_types}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.employeeTypes}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                            </Form.Row>
                                            <Form.Row>
                                                <Form.Group as={Col} md="8">
                                                    <Form.Label>Consulting Company (If applicable)</Form.Label>
                                                    <Form.Control required type="text" name={'consultantCompany'}
                                                                  onChange={handleChange}
                                                                  onBlur={handleBlur}
                                                                  placeholder={'Enter consulting company name here'}
                                                                  isInvalid={touched.consultantCompany && !!errors.consultantCompany}
                                                                  value={values.consultantCompany}
                                                    />
                                                    <Form.Control.Feedback type="invalid">
                                                        {errors.consultantCompany}
                                                    </Form.Control.Feedback>
                                                </Form.Group>
                                                <Form.Group as={Col} md="4">
                                                    <Form.Label>Effective Date</Form.Label>
                                                    <Field
                                                        id="joinDate"
                                                        name="joinDate"
                                                        component={FormikDatePickerComponent}
                                                    />
                                                </Form.Group>
                                            </Form.Row>
                                        </Card.Body>
                                        <Card.Footer>
                                            <ButtonToolbar>
                                                <Button onClick={this.close}>Cancel</Button>
                                                <Button variant="primary" disabled={isSubmitting} type="submit">Submit</Button>
                                            </ButtonToolbar>
                                        </Card.Footer>
                                    </Card>
                                </Form>
                            )}
                        </Formik>
                    </Modal.Body>
                </Modal>
            </Fragment>
        )
    }
}

SubjectOrgEditModal.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired
};

export default connect(null, {updateOrgSubject})(SubjectOrgEditModal);