/**
 * Created by pshivaraman on 7/9/19.
 */

import React, {Fragment, Component} from "react";
import {getEmployeeTypes} from "../../actions/fieldValue_actions";
import {CUR_ORG} from "../../actions/session_constants";
import {Field, reduxForm} from "redux-form";
import {
    FieldRBDatePicker,
    FieldRBDualListBox,
    FieldRBInput,
    FieldRBMaskedInput
} from "../redux_form/field_components_rb";
import Modal from "react-bootstrap/Modal";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {addOrgToSubject, fetchPerson, fetchSubjectByBusinessKey} from "../../actions/subject_actions";
import Button from "react-bootstrap/Button";

class AddSubjectToOrgModal extends Component {
    constructor(props){
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false,
            avail_employee_types: []
        }
    }

    open(){
        getEmployeeTypes().then((response) => {
            this.setState({
                avail_employee_types: response.data.map((empType) => {
                    return {
                        value: empType.id,
                        label: empType.longName
                    }
                })
            });
        });
        this.setState({
            showModal: true
        });
    }

    close(){
        this.setState({
            showModal: false
        });
    }

    onSubmit(values){
        const {subjectId, onFinish} = this.props;
        // Create relationship
        const subjectOrgRel = {
            orgId: sessionStorage.getItem(CUR_ORG),
            title: values.title,
            employeeTypes: values.employeeTypes,
            joinDate: values.joinDate
        };
        this.props.addOrgToSubject(subjectId, subjectOrgRel, () => {
            this.close();
            onFinish();
        });
    }

    render(){
        const {showModal, avail_employee_types} = this.state;
        const {handleSubmit} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>{this.props.buttonText ? this.props.buttonText : "Select Subject"}</Button>
                <Modal show={showModal} onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Header closeButton>
                            <Modal.Title>Add Subject to Organization</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <h4>Organization Details</h4>
                            <Field name="title" label="Job Title" component={FieldRBInput} required/>
                            <Field name="employeeTypes" label="Organization Roles" component={FieldRBDualListBox}
                                   options={avail_employee_types}/>
                            <Field name="joinDate" label="Join Date" component={FieldRBDatePicker} required />
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Cancel</Button>
                            <Button variant="primary" type="submit">Add Subject</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values){
    const errors = {};

    if (!values.title){
        errors.title = "Please Enter a Job Title!";
    }
    if (!values.joinDate){
        errors.joinDate = "Please Enter a Join Date!";
    }

    return errors;
}

function mapStateToProps({person}) {
    return {
        subject: person
    }
}

AddSubjectToOrgModal.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
    buttonText: PropTypes.string
};

export default reduxForm({
    validate,
    form: 'AddSubjectToOrgForm'
})(
    connect(mapStateToProps, {addOrgToSubject})(AddSubjectToOrgModal)
);