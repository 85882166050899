/**
 * Created by pshivaraman on 7/5/18.
 */

import React, {Component, Fragment} from "react";
import {FieldRBDatePicker, FieldRBDropzone} from "../redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {removeContractFromSubject, updateSubjectContractBriefing} from "../../actions/subject_actions";
import {DateUtils} from "../../actions/common_utils";
import PropTypes from "prop-types";
import Button from "react-bootstrap/Button";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";

// TODO: This needs updating to a cleaner format
class CreateBriefingModal extends Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);
        this.viewBrief = this.viewBrief.bind(this);
        this.updateBrief = this.updateBrief.bind(this);

        this.state = {
            showModal: false,
            updating: false,
        }
    }

    open() {
        this.setState({showModal: true});
    }

    viewBrief() {
        this.setState({
            updating: false
        });
        this.open();
    }

    updateBrief() {
        this.setState({
            updating: true
        });
        this.open();
    }

    close() {
        this.setState({showModal: false});
        this.props.reset();
    }

    onSubjectRemove = () => {
        const {subject, contractId, onFinish} = this.props;
        let contractSubjectRel = {
            contractId: contractId
        }
        this.props.removeContractFromSubject(subject.id, contractSubjectRel, () => {
            this.close();
            onFinish();
        });
    }


    onSubmit(values) {
        const {subject, contractId, onFinish} = this.props;
        const relObj = subject.contracts.find(c => c.contractId === contractId);

        relObj.documents = values.documents;
        if (!relObj.briefDate) {
            relObj.briefDate = values.briefingDate;
            relObj.inbrief = true;
        } else if (!relObj.debriefDate) {
            relObj.debriefDate = values.briefingDate;
            relObj.inbrief = false;
        } else {
            return;
        }
        this.props.updateSubjectContractBriefing(subject.id, relObj, () => {
            this.close();
            onFinish();
        });
    }

    getModalContent(relObj) {
        const {subject} = this.props;
        if (this.state.updating) {
            const briefingType = !relObj.briefDate ? "Brief" : !relObj.debriefDate ? "Debrief" : "None";
            return (
                <Fragment>
                    <Form.Group>
                        <Form.Label>Subject</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={`${subject.personFirstName} ${subject.personLastName}`} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Briefing Type</Form.Label>
                        <Form.Control plaintext readOnly defaultValue={briefingType} />
                    </Form.Group>
                    <Field name="briefingDate" component={FieldRBDatePicker} label="Date"/>
                    <Field name="documents" component={FieldRBDropzone} multiple label="Documents"/>
                </Fragment>
            )
        }
        return (
            <Fragment>
                <Form.Group>
                    <Form.Label>Subject</Form.Label>
                    <Form.Control plaintext readOnly defaultValue={`${subject.personFirstName} ${subject.personLastName}`} />
                </Form.Group>

                <h4>Briefing</h4>
                <Form.Group>
                    <Form.Label>Briefing Date</Form.Label>
                    <Form.Control plaintext readOnly defaultValue={DateUtils.getDateString(relObj.briefDate)} />
                </Form.Group>

                <h4>Debrief</h4>
                <Form.Group>
                    <Form.Label>Debrief Date</Form.Label>
                    <Form.Control plaintext readOnly defaultValue={DateUtils.getDateString(relObj.debriefDate)} />
                </Form.Group>
            </Fragment>
        )
    }

    render() {
        const {showModal} = this.state;
        const {subject, contractId, handleSubmit} = this.props;
        if (!subject || !subject.contracts) {
            return <Button disabled>Loading...</Button>;
        }

        const relObj = subject.contracts.find(c => c.contractId === contractId);

        if (!relObj) {
            return <Button disabled>Loading...</Button>;
        }
        const briefingType = !relObj.briefDate ? "Brief" : !relObj.debriefDate ? "Debrief" : "None";

        return (
            <Fragment>
                {/*{briefingType !== "None" &&*/}
                {/*<Button onClick={this.updateBrief}>{briefingType}</Button>*/}
                {/*}*/}
                {/*{briefingType !== "Brief" &&*/}
                {/*<Button onClick={this.viewBrief}>View Briefing</Button>*/}
                {/*}*/}
                {briefingType !== "Debrief" &&
                    ConditionalDisplay(<Button variant="danger" onClick={this.onSubjectRemove}>Remove</Button>)
                }

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Subject Briefing</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Body>
                            {this.getModalContent(relObj)}
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                {this.state.updating &&
                                <Button variant="primary" type="submit">Add Briefing</Button>
                                }
                                <Button type="button" onClick={this.close}>Close</Button>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

CreateBriefingModal.propTypes = {
    contractId: PropTypes.string.isRequired,
    subject: PropTypes.object.isRequired,
    onFinish: PropTypes.func.isRequired,
};

function validate(values) {
    const errors = {};
    if (!values.briefingDate) {
        errors.briefingDate = "You must enter a briefing date";
    }
}

export default reduxForm({
    validate,
    form: "CreateBriefingForm"
})(connect(null, {
    removeContractFromSubject,
    updateSubjectContractBriefing})(CreateBriefingModal))