/**
 * Created by shivaraman on 2/23/19.
 */
import React from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import Image from "react-bootstrap/Image";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";

class QrCode extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            didLoad : false
        }
    }

    onSubmit(values) {
        this.props.onContinue();
    }

    onLoad =() => {
        this.setState({
            didLoad: true
        })
    }

    render() {
        const {handleSubmit, qrCodeUrl} = this.props; // is the same as const handleSubmit=this.props.handleSubmit
        console.log(`qr code url is ${qrCodeUrl}`)
        return (
            <div>
                <form className="form-signin" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <h2 className="form-signin-heading">QR Code</h2>
                    <Form.Group>
                        <Form.Label>Please scan the QR Code on your mobile phone using Google Authenticator app. Please be patient, it may take a few seconds to load the QR code.</Form.Label>
                        <Image src={qrCodeUrl + "/594px352"} onLoad={this.onLoad}/>
                    </Form.Group>
                    <Button disabled={!this.state.didLoad} variant="primary" size="lg" block type="submit">Continue</Button>
                </form>
            </div>
        );
    }
}

QrCode.propTypes = {
    onContinue: PropTypes.func.isRequired,
    qrCodeUrl: PropTypes.string.isRequired
};

export default reduxForm({
    form: 'QrCodeForm'
})(
    connect(null, null)(QrCode)
);