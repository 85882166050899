import React, {Component, Fragment} from "react";
import {connect} from "react-redux";
import {Field, reduxForm} from "redux-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {FieldRBDatePicker, FieldRBDualListBox, FieldRBInput} from "../redux_form/field_components_rb";
import PropTypes from "prop-types";
import {removeOrgFromSubject} from "../../actions/subject_actions";
import {CUR_ORG} from "../../actions/session_constants";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";

class RemoveSubjectFromOrgModal extends Component {
    constructor(props){
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false
        }
    }

    onSubmit(values){
        const {subjectId, onFinish} = this.props;
        // Create relationship
        const subjectOrgRel = {
            orgId: sessionStorage.getItem(CUR_ORG),
            separationDate: values.separationDate
        };
        // Send data to server
        this.props.removeOrgFromSubject(subjectId, subjectOrgRel, () => {
            this.close();
            onFinish();
        });
    }

    open(){
        this.setState({
            showModal: true
        });
    }

    close(){
        this.setState({
            showModal: false
        });
    }

    render(){
        const {handleSubmit} = this.props;
        const {showModal} = this.state;

        return (
            <Fragment>
                {ConditionalDisplay(
                    <Button variant="primary" onClick={this.open}>{this.props.buttonText ? this.props.buttonText : "Remove Subject"}</Button>
                )}
                <Modal show={showModal} onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Header closeButton>
                            <Modal.Title>Remove Subject from Organization</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Field name="separationDate" label="Separation Date" component={FieldRBDatePicker} required/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Cancel</Button>
                            <Button variant="primary" type="submit">Remove Subject</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values){
    const errors = {};

    if (!values.joinDate){
        errors.joinDate = "Please Enter a Join Date!";
    }

    return errors;
}

RemoveSubjectFromOrgModal.propTypes = {
    subjectId: PropTypes.string.isRequired,
    onFinish: PropTypes.func.isRequired,
    buttonText: PropTypes.string
};

export default reduxForm({
    form: "RemoveSubjectFromOrgForm",
    validate
})(
    connect(null, {removeOrgFromSubject})(RemoveSubjectFromOrgModal)
)