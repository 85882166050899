/**
 * Created by shivaraman on 6/3/17.
 */
import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {TOKEN_KEY, SECOND_FACTOR, tokenRefreshRequest} from '../../actions/auth_actions';
import {getJwtExpirationTime} from "../../actions/fieldValue_actions";
import {SessionTimeoutModal} from "../../components/session_timeout_modal";

export default function (ComposedComponent) {
    class Authentication extends Component {

        componentWillMount() {
            if (!sessionStorage.getItem(TOKEN_KEY) || !sessionStorage.getItem(SECOND_FACTOR)) {
                this.props.history.push('/login');
            }
        }

        componentWillUpdate(nextProps) {
            if (!sessionStorage.getItem(TOKEN_KEY) || !sessionStorage.getItem(SECOND_FACTOR)) {
                this.props.history.push('/login');
            }
        }

        render() {
            return (
                <Fragment>
                    <ComposedComponent {...this.props} />
                    <SessionTimeoutModal
                        tokenValue={sessionStorage.getItem(TOKEN_KEY)}
                        getTokenExpiry={getJwtExpirationTime}
                        refreshTokenFunction={() => this.props.tokenRefreshRequest()}
                    />
                </Fragment>
            );
        }
    }

    function mapStateToProps(state) {
        return {authenticated: state.auth};
    }

    return connect(mapStateToProps, {tokenRefreshRequest})(Authentication);
}

