/**
 * Created by pshivaraman on 6/27/18.
 */

import React, {forwardRef, Fragment} from "react";
import {getControlLabel, getErrorMsg, getFormGroupAs, getValidationState} from "./field_utils";
import MaskedInput from 'react-input-mask';
import PropTypes from "prop-types";
import {downloadDocument} from "../../actions/doc_actions";
import Dropzone from "react-dropzone";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import DualListBox from "react-dual-listbox";
import {DatePicker} from "../bootstrap_addons/bootstrap-4-date-picker";
import DatePickerReact from "react-datepicker";
import Form from "react-bootstrap/Form";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import ListGroup from "react-bootstrap/ListGroup";
import Col from "react-bootstrap/Col";
import {Multiselect} from 'multiselect-react-dropdown';
import InputGroup from "react-bootstrap/InputGroup";
import {DateUtils} from "../../actions/common_utils";

export const FieldRBInput = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);

    const placeholderLabel = (field.placeholder ? field.placeholder : field.label)
    const formControl = (
        <React.Fragment>
            <Form.Control type={field.type} placeholder={placeholderLabel} pattern={field.pattern} min={field.min}
                          max={field.max} maxLength={field.maxLength} readOnly={field.readOnly} size={field.size}
                          step={field.step} disabled={field.disabled}
                          autoFocus={field.autoFocus} {...validationState} {...field.input} />
            <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </React.Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={12} md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldRBInputSrOnly = (field) => {
    const validationState = getValidationState(field);
    const labelClass = field.required ? " required" : "";
    const errorMsg = getErrorMsg(field);
    const as = getFormGroupAs(field);

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                <Form.Label srOnly className={labelClass}>{field.label}</Form.Label>
                <Form.Control type={field.type} placeholder={field.label} pattern={field.pattern} min={field.min}
                              max={field.max} maxLength={field.maxLength} readOnly={field.readOnly}
                              size={field.size} step={field.step}
                              disabled={field.disabled}
                              autoFocus={field.autoFocus} {...validationState} {...field.input} />
                <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldRBMaskedInput = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const formControl = (
        <Fragment>
            <Form.Control type={field.type} placeholder={field.label} readOnly={field.readOnly}
                          mask={field.mask} disabled={field.disabled} autoFocus={field.autoFocus}
                          as={MaskedInput} {...validationState} {...field.input}/>
            <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={5} md={4}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldRBRadio = (field) => {
    const validationState = getValidationState(field);
    const errorMsg = getErrorMsg(field);
    return (
        <Form.Group>
            <Form.Check type="radio" inline={field.inline} feedback={errorMsg}
                        label={field.label} {...validationState} {...field.input} />
        </Form.Group>
    );
};

export const FieldRBCheckbox = (field) => {
    const validationState = getValidationState(field);
    const errorMsg = getErrorMsg(field);
    return (
        <Form.Group>
            <Form.Check type="checkbox" inline={field.inline} feedback={errorMsg}
                        label={field.label} {...validationState} {...field.input} />
        </Form.Group>
    );
};

export const FieldRBTextArea = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const formControl = (
        <Fragment>
            <Form.Control as="textarea" placeholder={field.label} readOnly={field.readOnly}
                          disabled={field.disabled} {...validationState} {...field.input} />
            <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={5} md={4}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

/**
 * Simple dropdown of values
 * @param field
 * @returns {XML}
 */
export const FieldRBSelect = (field) => {
    let selectOptions = [];

    for (let opt in field.options) {
        selectOptions.push(<option key={opt}
                                   value={field.options[opt]}>{field.options[opt]}</option>)
    }

    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const formControl = (
        <Fragment>
            <Form.Control as="select" readOnly={field.readOnly}
                          disabled={field.disabled} {...validationState} {...field.input}>
                {selectOptions}
            </Form.Control>
            <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={12} md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

FieldRBSelect.propTypes = {
    options: PropTypes.arrayOf(PropTypes.string).isRequired
};

/**
 * Expects a Array<Objects> with keys {id, name}
 */
export const FieldRBSelectWithKeys = (field) => {
    let selectOptions = []

    for (let opt in field.options) {
        if (field.options[opt].displayName) {
            selectOptions.push(<option key={opt}
                                       value={field.options[opt].id}>{field.options[opt].displayName}</option>)
        } else {
            selectOptions.push(<option key={opt}
                                       value={field.options[opt].id}>{field.options[opt].name}</option>)
        }
    }

    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const formControl = (
        <Fragment>
            <Form.Control as="select" readOnly={field.readOnly}
                          disabled={field.disabled} {...validationState} {...field.input}>
                {selectOptions}
            </Form.Control>
            <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={12} md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

FieldRBSelectWithKeys.propTypes = {
    options: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string.isRequired,
        name: (props, propName, componentName) => {
            if (!props.name && !props.displayName) {
                return new Error(`One of props 'name' or 'displayName' was not specified in '${componentName}'.`);
            }
        },
        displayName: (props, propName, componentName) => {
            if (!props.name && !props.displayName) {
                return new Error(`One of props 'name' or 'displayName' was not specified in '${componentName}'.`);
            }
        }
    }))
    // options: PropTypes.arrayOf(PropTypes.shape({
    //     id: PropTypes.string.isRequired,
    //     name: PropTypes.string.isRequired
    // })).isRequired
};

export const FieldRBDropzone = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const files = Array.from(field.input.value);
    const multiple = field.multiple ? field.multiple : false;

    const onDropFiles = (acceptedFiles, rejectedFiles) => {
        // For Redux form
        if (field.input) {
            // Ensures that if multiple = false, length is 0
            if (multiple || files.length < 1) {
                const newFiles = [].concat(files, Array.from(acceptedFiles));
                field.input.onChange(newFiles)
            }
        } else {
            console.warn('redux-form-dropzone => Forgot to pass onChange props ?');
        }
    };

    const onChangeHandler = (e) => {
        e.preventDefault();
    };

    const removeFile = (i) => {
        // For Redux form
        if (field.input) {
            let newFiles = files;
            newFiles.splice(i, 1);
            field.input.onChange(newFiles);
        } else {
            console.warn('redux-form-dropzone => Forgot to pass onChange props ?');
        }
    };

    const createFileItem = (file, i) => {
        let link;
        if (file.docId) {// If File has a docId, that means we can download it.
            link = <a href="#" onClick={() => downloadDocument(file)}>{file.name}</a>;
        } else {// If not, don't allow downloads.
            link = file.name;
        }
        return (
            <ListGroupItem key={i}>
                {link}
                <Button variant={'link'} className="close" onClick={() => removeFile(i)}>
                    <span title={"Remove File"} aria-hidden="true"><i class="fa fa-times-circle"></i></span>
                </Button>
            </ListGroupItem>
        );
    };

    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const invalid = !!validationState.isInvalid;
    const className = `paes-dropzone${invalid ? " paes-dropzone-invalid" : ""}`;

    const formControl = (
        <Fragment>
            {!field.disabled &&
            <Dropzone multiple={field.multiple ? field.multiple : false}
                      accept={field.accept ? field.accept : ""} {...field.input} className={className}
                      activeClassName="paes-dropzone-active" onChange={onChangeHandler} onDrop={onDropFiles}>
                <Fragment>
                    <div><span className="fa fa-cloud-upload"/></div>
                    <div>Drag and drop files here, or click to upload manually.</div>
                    <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
                </Fragment>
            </Dropzone>
            }
            <ListGroup>
                {files && (
                    files.map((file, i) => createFileItem(file, i))
                )}
            </ListGroup>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={12} md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldRBCountrySelector = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const formControl = (
        <Fragment>
            <CountryDropdown readOnly={field.readOnly} disabled={field.disabled} {...validationState} {...field.input}
                             labelType="full" valueType="short" classes={`form-control${validationState.isInvalid ? " is-invalid" : ""}`}
                             priorityOptions={["US"]}
            />
            <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldRBRegionSelector = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const country = field.country ? field.country : "";
    const formControl = (
        <Fragment>
            <RegionDropdown readOnly={field.readOnly} disabled={field.disabled} {...validationState} {...field.input}
                            country={country} labelType="full" countryValueType="short" valueType="short"
                            classes={`form-control${validationState.isInvalid ? " is-invalid" : ""}`}/>
            <Form.Control.Feedback type="invalid">{errorMsg}</Form.Control.Feedback>
            {field.children}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldMultiSelectDropdown = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const as = getFormGroupAs(field);
    const controlLabel = getControlLabel(field);

    const formControl = (
        <Fragment>
            <Multiselect options={field.options} selectedValues={field.selectedValues} onSelect={field.onSelect}
                         onRemove={field.onRemove} displayValue={field.displayValue}
            />
            {/*<Multiselect options={field.options} selectedValues={field.selectedValues} onSelect={field.onSelect}*/}
            {/*             onRemove={field.onRemove} displayValue={field.displayValue}*/}
            {/*/>*/}
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    );
}

export const FieldRBDualListBox = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const as = getFormGroupAs(field);
    const selected = Array.from(field.input.value);
    const options = field.options;
    const canFilter = field.canFilter && field.canFilter === true;

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                <Form.Label className={field.required ? "required" : ""}>{field.label}</Form.Label>
                <DualListBox {...validationState} {...field.input} options={options} readOnly={field.readOnly}
                             disabled={field.disabled} selected={selected} canFilter={canFilter}/>
                <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
};

export const FieldRBDatePicker = (field) => {
    const errorMsg = getErrorMsg(field);
    const validationState = getValidationState(field);
    const controlLabel = getControlLabel(field);
    const as = getFormGroupAs(field);
    const dateFormat = (field.dateFormat ? field.dateFormat : 'MM/dd/yyyy');
    const placeholderLabel = (field.placeholder ? field.placeholder : field.label)

    const DateCustomInput = forwardRef(({value, onClick, onChange}, ref) => (
        <InputGroup>
            <Form.Control type={field.type} placeholder={placeholderLabel} ref={ref} autoFocus={field.autoFocus}
                          {...validationState} value={value} onClick={onClick} onChange={onChange} />
            <InputGroup.Append>
                <Button variant="secondary" onClick={() => field.input.onChange(null)}>x</Button>
            </InputGroup.Append>
        </InputGroup>
    ));

    const formControl = (
        <Fragment>
            <DatePickerReact selected={DateUtils.getDate(field.input.value)}  onChange={(date) => {
                if (date === null){
                    field.input.onChange(null);
                } else {
                    field.input.onChange(date.toISOString());
                }
            }} readOnly={field.readOnly} disabled={field.disabled}
                             dateFormat={dateFormat} {...field}

            />
            <Form.Control.Feedback type="is-invalid">{errorMsg}</Form.Control.Feedback>
            {
                field.children
            }
        </Fragment>
    );

    return (
        <Fragment>
            <Form.Group as={as} controlId={field.input.name}>
                {controlLabel}
                {field.horizontal ? <Col sm={12} md={12}>{formControl}</Col> : formControl}
            </Form.Group>{field.inline ? " " : ""}
        </Fragment>
    )
}