/**
 * Created by pshivaraman on 7/26/18.
 */

import React, {Component, Fragment} from "react";
import {FieldRBDatePicker, FieldRBInput, FieldRBMaskedInput} from "../redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import {connect} from "react-redux";
import {
    addOrgToSubject,
    fetchPerson,
    fetchSubjectByBusinessKey,
    fetchSubjectBySsn,
    updatePerson
} from "../../actions/subject_actions";
import PropTypes from "prop-types";
import SubjectNewModal from "./subject_new_modal";
import {CUR_ORG} from "../../actions/session_constants";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import AddSubjectToOrgModal from "./add_subject_to_org_modal";

class SubjectSearchModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onCreate = this.onCreate.bind(this);
        this.onFinish = this.onFinish.bind(this);
        this.state = {
            showModal: false,
            values: {},
            noSubjectFound: false
        }
    }

    open() {
        this.setState({
            showModal: true,
            values: {},
            noSubjectFound: false
        });
    }

    onSubmit = (values) => {
        this.setState({noSubjectFound: false});
        this.props.initialize(values);
        this.props.fetchSubjectBySsn(values.ssn, (error) => {
            if (error.response.status === 404) {
                this.setState({noSubjectFound: true});
            }
        });
        this.setState({values: values});
    }

    onFinish() {
        const {subject, onSelect} = this.props;
        if (subject) {
            onSelect(subject);
            this.close();
        }
    }

    onCreate(ssn) {
        this.onSubmit({ssn: ssn});
        this.props.handleSubmit(this.onSubmit.bind(this));
    }

    close() {
        // Clear form fields
        this.props.change("ssn", null);

        this.setState({
            showModal: false
        });
    }

    getResult() {
        const {subject, dirty, valid, anyTouched} = this.props;
        const {noSubjectFound} = this.state;

        if (!dirty && !anyTouched && valid) {
            if (!subject || !subject.id || noSubjectFound) {
                return <Alert variant="danger">No Subject Found</Alert>;
            }
            return <Alert variant="success">Found {subject.personFirstName} {subject.personLastName}</Alert>;
        }
    }

    getButton() {
        const {subject, dirty, valid, anyTouched, sysAdminUserCreation, onCreateNew} = this.props;
        const {noSubjectFound} = this.state;

        if (dirty || anyTouched || !valid) {
            return <Button variant="primary" type="submit">Search</Button>;
        }
        if (subject && subject.id && !noSubjectFound) {
            if (sessionStorage.getItem(CUR_ORG) && !sysAdminUserCreation &&
                (!subject.orgs ||
                    !subject.orgs.find((org) => org.orgId === sessionStorage.getItem(CUR_ORG) && !org.deleted && !org.separationDate))) {
                return <AddSubjectToOrgModal subjectId={subject.id} onFinish={this.onFinish}/>
            }
            return <Button variant="primary" onClick={this.onFinish}>Select</Button>
        }
        if (onCreateNew){
            return <Button variant="primary" onClick={()=>{
                this.close();
                onCreateNew(this.state.values);
            }}>Create</Button>
        } else {
            return <SubjectNewModal onCreate={this.onCreate} buttonText="Create" subjectPrefill={this.state.values}/>
        }
    }

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Not allowing this, since it causes issues with selecting subject. Click the Search button instead
        }
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit, buttonText} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>{buttonText ? buttonText : "Select Subject"}</Button>
                <Modal show={showModal} onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))} onKeyPress={this.onKeyPress}>
                        <Modal.Header closeButton>
                            <Modal.Title>Subject Search</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Field name="ssn" component={FieldRBMaskedInput} mask="*99-99-9999"
                                   label="Social Security Number (SSN)" required/>

                            {this.getResult()}
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Cancel</Button>
                            {this.getButton()}
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.ssn) {
        errors.ssn = "SSN is required";
    } else if (!/^[0-9A-Za-z][0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(values.ssn)) {
        errors.ssn = "Enter a valid SSN";
    }

    return errors;
}

function mapStateToProps({person}) {
    return {
        subject: person
    }
}

SubjectSearchModal.propTypes = {
    buttonText: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    onCreateNew: PropTypes.func,
    sysAdminUserCreation: PropTypes.bool
};

export default reduxForm({
    validate, // same as validate:validate
    form: 'SubjectSearchForm'
})(
    connect(mapStateToProps, {fetchSubjectBySsn, fetchPerson, addOrgToSubject})(SubjectSearchModal)
);