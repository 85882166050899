/**
 * Created by pshivaraman on 11/12/18.
 */

import React, {Component, Fragment} from "react";
import {FieldRBCheckbox, FieldRBInput} from "../redux_form/field_components_rb";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {showSuccessGrowl, showErrorGrowl} from "../../actions/msg_actions";
import {connect} from "react-redux";
import {updateUserPassword} from "../../actions/user_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";

class UserPasswordResetModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false
        }
    }

    open() {
        this.setState({
            showModal: true
        });
        this.props.change("qrReset", false);
        this.props.change("passwordReset", false);
    }

    onSubmit(values) {
        if (values.passwordReset === true || values.qrReset === true) {
            values.userId = this.props.user.id;
            this.props.updateUserPassword(values, () => {
                this.props.showSuccessGrowl("Password Change Initiated", "Password reset mail sent to user.");
                this.close();
            });
        } else {
            throw new SubmissionError({
                _error: 'Please check at least one option'
            });
        }
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit, error} = this.props;

        return (
            <Fragment>
                {ConditionalDisplay(
                <Button variant="link" onClick={this.open} className={"close"}>
                    <span aria-hidden="true" title={"Reset Password/QR Code"} aria-label="Reset Password/QR Code">
                        <i className="fa fa-user-lock" />
                    </span>
                </Button>
                )}
                <Modal show={showModal} onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Header closeButton>
                            <Modal.Title>Reset Password</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {error && <Alert variant="danger">{error}</Alert>}
                            <Field name="qrReset" component={FieldRBCheckbox} label="Reset Second Factor Code"
                                   type="checkbox" horizontal/>
                            <Field name="passwordReset" component={FieldRBCheckbox} label="Reset Password"
                                   type="checkbox" horizontal/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Cancel</Button>
                            <Button variant="primary" type="submit">Reset</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    return errors;
}

export default reduxForm({
    validate,
    form: 'UserPasswordResetModalForm'
})(
    connect(null, {showSuccessGrowl, showErrorGrowl, updateUserPassword})(UserPasswordResetModal)
);