/**
 * Created by pshivaraman on 7/26/18.
 */

import React, {Component, Fragment} from "react";
import {FieldRBInput, FieldRBSelectWithKeys} from "../redux_form/field_components_rb";
import {Field, reduxForm, SubmissionError} from "redux-form";
import SubjectSearchModalForSys from "../subject/subject_search_modal_for_sys";
import {FieldInvisibleInput} from "../redux_form/field_components_custom";
import {connect} from "react-redux";
import {createUser, fetchUserName, updateSysAdmin} from "../../actions/user_actions";
import {showSuccessGrowl} from "../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import PropTypes from "prop-types";
import {ROLES} from "../../actions/auth_actions";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Steps} from "primereact/steps";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";
import {fetchSubjectBySsn} from "../../actions/subject_actions";

class CreateUserModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.onSubjectSelect = this.onSubjectSelect.bind(this);
        this.state = {
            showModal: false,
            sysAdminCreation: false,
            selectedSubject: null,
            selectedSubjectId: null,
            page: 1,
            sizePerPage: 5,
            activeStep: 0,
            subjId: null,
            newUser: true
        }
    }

    open(isSysUser) {
        const {subject} = this.props;
        if (subject) {
            this.onSubjectSelect(subject);
        }
        this.setState({
            showModal: true,
            sysAdminCreation: isSysUser
        });
    }

    onSubjectSelect(subject, userNameObject) {
        console.log('Subject is: ', subject);
        console.log(userNameObject);
        this.props.change("personId", subject.id);
        this.props.change("subjectName", `${subject.personFirstName} ${subject.personLastName}`);
        this.props.change("userName", userNameObject.userName);
        this.setState({activeStep: 1, subjId: subject.id, newUser: userNameObject.newUser});
        console.log(this.props.children);

    }

    onSubmit(values) {
        const {newUser} = this.state;
        values.adminUserCreation = this.state.sysAdminCreation;
        if (newUser) {
            return this.props.createUser(values, () => {
                // Success Response
                this.props.showSuccessGrowl("User Created", "User was created successfully");
                this.props.onCreate();
                this.close();
            }, (message) => {
                // Failure Response
                throw new SubmissionError({
                    _error: message
                });
            });
        } else {
            console.log('Submitting a preexisting user form');
            let updatedUser = updateSysAdmin(values.userName, () => {
                this.props.showSuccessGrowl("User Updated", "User was updated successfully");
                this.close();
            }, (message) => {
                throw new SubmissionError({
                    _error: message
                });
            });

            console.log(updatedUser);


        }
    }

    getSteps = () => {
        const {newUser} = this.state;
        let secondStepName = "Create User";
        if (!newUser) {
            secondStepName = "Assign Role";
        }
        const stepItems = [
            {label: "Select Subject"},
            {label: secondStepName}
        ];

        const onActiveIndexChange = (e) => this.setState({activeStep: e.index});
        return (
            <Steps model={stepItems} activeIndex={this.state.activeStep}
                   activeIndexChange={onActiveIndexChange.bind(this)} className = "user-create-steps"/>
        )
    };

    getUserFeedbackMessage = () => {
        let {newUser, sysAdminCreation} = this.state;
        if (newUser){
            return (
                <Alert variant={'info'}>
                    Password reset email link will be sent to the subject's email. Only
                    username needs to be communicated to the subject
                </Alert>
            );
        } else if (sysAdminCreation) {
            return (
                <Alert variant={'info'}>
                    User will be notified of the system administrator role
                </Alert>
            );
        } else {
            // this is an existing user. No need to do this again
            return (
                <Alert variant={'warn'}>
                    User already exists
                </Alert>
            );
        }
    }

    userFormCreateUser() {

        const {handleSubmit, error} = this.props;
        let {newUser, sysAdminCreation} = this.state;

        return (
            <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                <Modal.Body>
                    {error && <Alert variant="danger">{error}</Alert>}
                    <Row>
                        <Col md={{span: 6, order: 'last'}}>
                            {this.getUserFeedbackMessage()}
                        </Col>
                        <Col md={{span: 6, order: 'first'}}>
                            <Fragment>
                                <Field name="userName" component={FieldRBInput} label="User Name" disabled/>
                            </Fragment>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.close}>Cancel</Button>
                    <Button onClick={this.previousPage}>Previous</Button>
                    {/*Only for sysadmin user creation the assign role is ok. For regular user not needed as there is no role to assign*/}
                    {(sysAdminCreation || newUser) &&
                    <Button variant="primary" type="submit">{newUser ? "Create" : "Assign role"}</Button>
                    }
                </Modal.Footer>
            </form>
        );
    }

    previousPage = () => {
        this.setState({activeStep: 0});
    }

    renderStepContent = () => {
        const {sysAdminUserCreation} = this.props;
        switch (this.state.activeStep) {
            case 0:
                return <SubjectSearchModalForSys onSelect={this.onSubjectSelect} sysAdminUserCreation={sysAdminUserCreation} onClose={this.close}/>

            case 1:
                return this.userFormCreateUser();
        }
    }

    close = async() =>  {
        this.props.change("userName", null);
        this.props.change("personId", null);
        this.props.change("subjectName", null);

        // Resetting the selected subject
        await fetchSubjectBySsn(null, (error) => {
            console.log(error);
        });

        this.setState({
            showModal: false,
            sysAdminCreation: false,
            activeStep: 0
        });
    }

    hasSysAdmin() {
        const roles = sessionStorage.getItem(ROLES);
        return (roles && roles !== "undefined" && JSON.parse(roles).filter((role) => role.role === "SYS_ADMIN").length > 0) // Contains SYS_ADMIN role
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit, error, sysAdminUserCreation} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={() => this.open(false)}>Create User</Button>{' '}
                {this.hasSysAdmin() &&
                <Button variant="primary" onClick={() => {
                    this.open(true);
                }}>Create Sys Admin User</Button>
                }
                <Modal show={showModal} onHide={this.close}>
                    {this.getSteps()}
                    {this.renderStepContent()}

                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.userName) {
        errors.userName = "Enter a user name";
    }
    if (!values.personId) {
        errors.subjectName = "Select a subject!";
    }

    return errors;
}

CreateUserModal.propTypes = {
    sysAdminUserCreation: PropTypes.bool.isRequired
}

export default reduxForm({
    validate,
    form: 'CreateUserForm'
})(
    connect(null, {createUser, showSuccessGrowl})(CreateUserModal)
);