import Card from "react-bootstrap/Card";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {Form} from "redux-form";
import {DateUtils} from "../../actions/common_utils";
import LodgingList from "../../containers/reporting/subject/foreignTravel/lodging_list";
import ForeignContactList from "../../containers/reporting/subject/foreignTravel/foreign_contact_list";
import PropTypes from "prop-types";
import React, {Component, Fragment} from "react";
import CollapsibleCard from "../bootstrap_addons/collapsible-card";
import {QuestionnaireQandA} from "./questionnaire_travel_contact";
import {ForeignCountryVisitList} from "../../containers/org/dash/subject/details/subject_foreign_travel_country_visits";

export class ForeignTravelDetailComponent extends Component {

    render() {
        return (
            <Fragment>
                <CollapsibleCard header={'Itinerary'} defaultOpen={true}>
                    <Card.Body>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Reason for Travel
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.travelReason}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Countries Visited
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.countriesVisited? this.props.foreignTravelDetail.countriesVisited.join():this.props.foreignTravelDetail.countriesVisited}
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Date of Departure
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(this.props.foreignTravelDetail.departureDate)}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Date of Return
                            </Col>
                            <Col sm={3} md={4}>
                                {DateUtils.getDateString(this.props.foreignTravelDetail.arrivalDate)}
                            </Col>
                        </Row>
                    </Card.Body>
                </CollapsibleCard>
                <CollapsibleCard header={'Emergency Contact'}>
                    <Card.Body>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Name
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.emergencyContactName}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Phone No.
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.emergencyContactPhone}
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Email
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.emergencyContactEmail}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Contact Address
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.emergencyContactAddress}
                            </Col>
                        </Row>
                    </Card.Body>
                </CollapsibleCard>
                {this.props.foreignTravelDetail.passportDetails &&
                <CollapsibleCard header={'Passport Details'}>
                    <Card.Body>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Passport Number
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.passportDetails.passportNumber}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Issue Date
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.passportDetails.issueDate}
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Expiry Date
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.passportDetails.expiryDate}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Passport Type
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.passportDetails.passportType}
                            </Col>
                        </Row>
                    </Card.Body>
                </CollapsibleCard>
                }
                {this.props.foreignTravelDetail.transportation &&
                <CollapsibleCard header={'Transportation Information'}>
                    <Card.Body>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Departure Carrier Name
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.transportation.departureCarrierName}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Depart From (From US)
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.transportation.departFrom}
                            </Col>
                        </Row>
                        <Row className={"mb-3"}>
                            <Col as={Form.Label} sm={3} md={2}>
                                Arrival Carrier Name
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.transportation.arrivalCarrierName}
                            </Col>
                            <Col as={Form.Label} sm={3} md={2}>
                                Arrive From (Return to US)
                            </Col>
                            <Col sm={3} md={4}>
                                {this.props.foreignTravelDetail.transportation.arriveFrom}
                            </Col>
                        </Row>
                    </Card.Body>
                </CollapsibleCard>
                }
                {this.props.foreignTravelDetail.lodges &&
                <CollapsibleCard header={'Lodging'}>
                    <Card.Body>
                        <LodgingList updateParentStateWhenSubmitting={() => {
                        }}
                                     addedLodges={this.props.foreignTravelDetail.lodges}
                                     lodgeAdded={() => {
                                     }}
                                     lodgeRemoved={() => {
                                     }} viewOnly={true}/>
                    </Card.Body>
                </CollapsibleCard>
                }
                {this.props.foreignTravelDetail.countryVisits &&
                <CollapsibleCard header={'Country Visit Details'}>
                    <Card.Body>
                        <ForeignCountryVisitList addedForeignCountryVisits={this.props.foreignTravelDetail.countryVisits}/>
                    </Card.Body>
                </CollapsibleCard>
                }
                {this.props.foreignTravelDetail.foreignContacts &&
                <CollapsibleCard header={'Foreign Contacts'}>
                    <Card.Body>
                        <ForeignContactList updateParentStateWhenSubmitting={() => {
                        }}
                                            addedContacts={this.props.foreignTravelDetail.foreignContacts}
                                            contactAdded={() => {
                                            }}
                                            contactRemoved={() => {
                                            }} viewOnly={true}/>
                    </Card.Body>
                </CollapsibleCard>
                }
                {this.props.foreignTravelDetail.generalQuestions &&
                <CollapsibleCard header={'Foreign Contact Questionnaire Response'}>
                    <QuestionnaireQandA questionnaireResp={this.props.foreignTravelDetail}/>
                </CollapsibleCard>
                }
                {this.props.foreignTravelDetail.postTravelResponse &&
                <CollapsibleCard header={'Post Travel Response'}>
                    <QuestionnaireQandA questionnaireResp={this.props.foreignTravelDetail.postTravelResponse}/>
                </CollapsibleCard>
                }
            </Fragment>
        )
    }
}

ForeignTravelDetailComponent.propTypes = {
    foreignTravelDetail: PropTypes.any
};
