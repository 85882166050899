/**
 * Created by pshivaraman on 12/20/18.
 */

import React, {Component, Fragment} from "react";
import IdleTimer from "react-idle-timer";
import {logoutUser, TOKEN_KEY, tokenRefreshRequest} from "../actions/auth_actions";
import {connect} from "react-redux";
import {sessionTimeoutHandler} from "../actions/error_actions";
import {getJwtExpirationTime} from "../actions/fieldValue_actions";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";

export class SessionTimeoutModal extends Component {
    constructor(props) {
        super(props);

        this.idleTimer = null;
        this.expirationTimer = null;
        this._isMounted = false;
        this.onAction = this.onAction.bind(this);
        this.onIdle = this.onIdle.bind(this);
        this.timerTick = this.timerTick.bind(this);
        this.onContinueWorking = this.onContinueWorking.bind(this);
        this.onLogoutUser = this.onLogoutUser.bind(this);

        this.state = {
            showWarningModal: false,
            timeLeft: 60,
            tokenExpirationTime: 1000 * 60 * 4 // Default to 4 minutes
        }
    }

    componentWillMount() {
        this._isMounted = true;
        // Fetch the expiration time from the backend (changes based on environment)
        if (this.props.tokenValue) { //  sessionStorage.getItem(TOKEN_KEY)
            this.props.getTokenExpiry().then(response => { //  getJwtExpirationTime()
                this._isMounted && this.setState({
                    tokenExpirationTime: (response.data - 1) * 60000, // Subtract a minute and convert to ms
                    tokenExpiredTime: new Date(new Date().getTime() + response.data * 60000)
                });
                this.idleTimer && this.idleTimer.reset();
            });
        }
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    onAction(e) {
        // Refreshes to token when the user does something
        if (!this.state.showWarningModal) {
            this.props.refreshTokenFunction();
        }
    }

    onIdle(e) {
        this.setState({
            showWarningModal: true,
            timeLeft: 60
        });
        this.expirationTimer = setInterval(this.timerTick, 1000);
    }

    timerTick() {
        const {timeLeft} = this.state;
        let {tokenExpiredTime} = this.state;
        if (timeLeft <= 0 || !this._isMounted || tokenExpiredTime < new Date()) {
            clearInterval(this.expirationTimer); // Stop Timeout Timer
            sessionTimeoutHandler(); // Show Session Timeout Modal
        }
        this._isMounted && this.setState({
            timeLeft: this.state.timeLeft - 1
        });
    }

    onContinueWorking() {
        // Refresh Token
        this.props.refreshTokenFunction();// Refresh Token
        this.idleTimer.reset();// Reset Idle Timer
        clearInterval(this.expirationTimer); // Stop Timeout Timer
        this.setState({ // Hide Warning Modal
            showWarningModal: false
        });
    }

    onLogoutUser(){
        clearInterval(this.expirationTimer); // Stop Timeout Timer
        logoutUser();
    }

    render() {
        const {showWarningModal, tokenExpirationTime, timeLeft} = this.state;

        return (
            <Fragment>
                <IdleTimer ref={ref => {
                    this.idleTimer = ref
                }}
                           element={document}
                           onIdle={this.onIdle}
                           onAction={this.onAction}
                           debounce={1000}
                           stopOnIdle={true}
                           timeout={tokenExpirationTime}/>

                <Modal show={showWarningModal} backdrop={'static'}>
                    <Modal.Header>
                        <Modal.Title>Your Session Will Time Out Soon!</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <p>To protect your data, the system automatically times out your session after a period of
                            inactivity.</p>
                        <p>Your session will time out in {timeLeft} seconds</p>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.onLogoutUser}>Log Out</Button>
                        <Button onClick={this.onContinueWorking} variant="primary">Continue Working</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}