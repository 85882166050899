/**
 * Created by shivaraman on 11/13/17.
 */

import React, {Component} from "react";
import BreadcrumbLink from "../../components/bootstrap_addons/breadcrumb_link";
import {FieldRBCheckbox, FieldRBInput} from "../../components/redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import {updateUserPassword} from "../../actions/user_actions";
import {connect} from "react-redux";
import {USER_ID, logoutUser} from "../../actions/auth_actions";
import {showSuccessGrowl} from "../../actions/msg_actions";
import Breadcrumb from "react-bootstrap/Breadcrumb";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Button from "react-bootstrap/Button";
import { FormGroup } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

class UserProfile extends Component {
    constructor(props) {
        super(props);
    }

    // getBreadCrumb() {
    //     return (
    //         <Breadcrumb>
    //             <BreadcrumbLink to="/account">
    //                 Account
    //             </BreadcrumbLink>
    //             <Breadcrumb.Item active>
    //                 Profile
    //             </Breadcrumb.Item>
    //         </Breadcrumb>
    //     )
    // }

    cancelUpdate = () => {
        this.props.history.push("/org/list");
    }
    onSubmit(values){
        if (values.password || values.qrReset === true) {
            values.userId = sessionStorage.getItem(USER_ID);
            this.props.updateUserPassword(values, () => {
                // this.props.history.push("/account/profile");
                this.props.showSuccessGrowl("User Profile Changed", "User Profile changed successfully.");
                this.props.change("password", "");
                this.props.change("passwordConf", "");
                this.props.logoutUser();
            });
        }
    }

    render() {
        const {handleSubmit} = this.props;
        return (
            <div className="reporting_bg p-0">
                {/* {this.getBreadCrumb()} */}
                    <div className="container">
                   
                        <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <div class="heading_container">  <h3>User Profile (Changes will sign you out)</h3></div>
                          
                          <div className="reporting_screen1 container">
                           
                            <h4>Reset Second Factor Code</h4>
                            
                         
                            <Field name="qrReset" component={FieldRBCheckbox} label="Reset Second Factor Code"
                                type="checkbox" horizontal/>
                            <h4>Reset Password</h4>
                            <Row>
                                <Col>
                                    <Field name="password" component={FieldRBInput} label="Password" type="password"/>
                                </Col>

                                <Col>
                                    <Field name="passwordConf" component={FieldRBInput} label="Confirm Password"
                                    type="password"/>
                                </Col>
                            </Row>
                            
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Update</Button>
                                <Button variant="danger" type="button" onClick={this.cancelUpdate}>Cancel</Button>
                            </ButtonToolbar>
                          </div>
                          
                        </form>
                    </div>
                
            </div>
        );
    }
}

function validate(values) {
    const errors = {};

    if (values.password && values.password !== values.passwordConf) {
        errors.password = "Passwords must match!";
        errors.passwordConf = "Passwords must match!";
    }

    return errors;
}

export default reduxForm({
    validate,
    form: "UserProfileUpdateForm"
})(connect(null, {updateUserPassword, showSuccessGrowl, logoutUser})(UserProfile));