import React, {Fragment} from 'react';
import {Row, Col, Card} from "react-bootstrap";
import PropTypes from 'prop-types';
import {Form} from "redux-form";

export function QuestionnaireResponses(props) {
    let {questionnaireResp} = props;
    return (
        <Fragment>
            <Row>
                <Col xs={6}>
                    <h6>Question</h6>
                </Col>
                <Col xs={1}>
                    <h6>Ans</h6>
                </Col>
                <Col xs={5}>
                    <h6>Comments</h6>
                </Col>
            </Row>
            {renderIfAny(questionnaireResp.generalQuestions)}
            <Row className={"mb-3"}>
                <Col as={Form.Label} sm={3} md={2}>
                    Additional Comments
                </Col>
                <Col sm={3} md={4}>
                    {questionnaireResp.additionalComments}
                </Col>
            </Row>
        </Fragment>
    )
}

export function QuestionnaireQandA(props) {
    let {questionnaireResp} = props;
    return (
        <Card.Body>
            <Card.Title>
                <Row>
                    <Col xs={6}>
                        <h6>Question</h6>
                    </Col>
                    <Col xs={1}>
                        <h6>Ans</h6>
                    </Col>
                    <Col xs={5}>
                        <h6>Comments</h6>
                    </Col>
                </Row>
            </Card.Title>
            {renderIfAny(questionnaireResp.generalQuestions)}
            <Row className={"mb-3"}>
                <Col as={Form.Label} sm={3} md={2}>
                    Additional Comments
                </Col>
                <Col sm={3} md={4}>
                    {questionnaireResp.additionalComments}
                </Col>
            </Row>

        </Card.Body>
    )
}

QuestionnaireQandA.propTypes = {
    questionnaireResp: PropTypes.object.isRequired
}

function renderIfAny(qAndAResponses) {
    let rowArray = [];
    if (qAndAResponses) {
        qAndAResponses.forEach((eachResponse) => {
            rowArray.push(
                <Row key={name}>
                    <Col xs={6}>
                        {eachResponse.questionType.displayName}
                    </Col>
                    <Col xs={1}>
                        {eachResponse.response ? 'Yes' : 'No'}
                    </Col>
                    <Col xs={5}>
                        {eachResponse.comments}
                    </Col>
                </Row>
            )
        })
    }
    return rowArray;
}
