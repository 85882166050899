import React from "react";
import debounce from "lodash/debounce";
import {connect} from "react-redux";
import {fetchOrgsByCageCode, fetchOrgsByName} from "../actions/org_actions";
import Card from "react-bootstrap/Card";
import Form from "react-bootstrap/Form";

// Container component that will be aware of redux and will need to send out actions
class SearchBar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            cageCode: "",
            name: ""
        }
    }

    findOrgsByCageCode = debounce(() => {
        this.props.fetchOrgsByCageCode(this.state.cageCode);
    }, 300);

    findOrgsByName = debounce(() => {
        this.props.fetchOrgsByName(this.state.name);
    }, 300);

    onNameChange(event) {
        this.setState({
            name: event.target.value,
            cageCode: ""
        });
        this.findOrgsByName();
    }

    onCageCodeChange(event) {
        this.setState({
            name: "",
            cageCode: event.target.value
        });
        this.findOrgsByCageCode();
    }

    render() {
        return (
            <div>
                <Card className="border-blue">
                    <Card.Header className='border-0 pt-4'>
                        <Card.Title>
                            Organization Search
                           
                        </Card.Title>
                        <p>Search by Organization or Location, and get a list of Organizations matched</p>
                        </Card.Header>
                    <Card.Body>
                       
                        <Form>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Type to begin searching..."
                                              value={this.state.name}
                                              onChange={this.onNameChange.bind(this)}/>
                            </Form.Group>

                            <Form.Group>
                                <Form.Label>Cage Code</Form.Label>
                                <Form.Control type="text" placeholder="Type to begin searching..."
                                              value={this.state.cageCode}
                                              onChange={this.onCageCodeChange.bind(this)}/>
                            </Form.Group>
                        </Form>
                    </Card.Body>
                </Card>
            </div>

        );
    }
}

// Connects the React component Search Bar to the action and redux dispatch
export default connect(null, {fetchOrgsByCageCode, fetchOrgsByName})(SearchBar);