/**
 * Created by pshivaraman on 1/9/19.
 */

import React, {Component, Fragment} from "react";
import {Field, reduxForm} from "redux-form";
import {FieldRBSelectWithKeys} from "../redux_form/field_components_rb";
import {CUR_ORG} from "../../actions/session_constants";
import connect from "react-redux/es/connect/connect";
import {addServiceCenterToOrg, getUsersByRole} from "../../actions/user_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

class UserAssignServiceCenterModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.afterClose = this.afterClose.bind(this);

        this.state = {
            avail_users: [],
            showModal: false
        }
    }

    componentDidMount() {
        this.setState({
            avail_users: [],
            showModal: false
        });
    }

    open() {
        getUsersByRole("ORG", sessionStorage.getItem(CUR_ORG), "SERV_CTR", true).then(response => {
            this.setState({
                avail_users: response.data
            });
        });
        this.setState({
            showModal: true
        });
    }

    close() {
        this.setState({
            showModal: false
        });

        // reset() breaks validation in redux-form (a bug as of v7.4.2).
        // Use the following to work around the issue to allow validation
        this.props.reset();
        setTimeout(this.afterClose, 500);
    }

    afterClose() {
        this.props.change("user", null);
    }

    onSubmit(values) {
        this.props.addServiceCenterToOrg(sessionStorage.getItem(CUR_ORG), this.props.managedOrgId, values.user, () => {
            this.props.onAssignUser();
            this.close();
        });
    }

    getUserOptions() {
        const options = [{
            id: "",
            name: "Select User..."
        }];
        this.state.avail_users.map((user) => {
            options.push({
                id: user.id,
                name: user.userName
            });
        });
        if (this.props.existingUsers) {
            return options.filter((user) => {
                return !this.props.existingUsers.find((o) => user.id === o.id);
            });
        }
        return options;
    }

    render() {
        const {handleSubmit} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={() => this.open()}>
                    Assign Personnel
                </Button>
                <Modal show={this.state.showModal} onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Header closeButton>
                            <Modal.Title>Assign Service Center Personnel</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            <Field name="user" label="User" component={FieldRBSelectWithKeys}
                                   options={this.getUserOptions()}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Close</Button>
                            <Button variant="primary" type="submit">Assign</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

function validate(values) {
    const errors = {};

    if (!values.user || values.user === "") {
        errors.user = "You must select a user!";
    }

    return errors;
}

export default reduxForm({
    validate,
    form: 'ServiceCenterOrgAddUserForm'
})(
    connect(null, {addServiceCenterToOrg})(UserAssignServiceCenterModal)
);