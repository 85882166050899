import React from "react";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import PropTypes from 'prop-types';
import {generateReport} from '../actions/subject_actions';

class TableExportComponent extends React.Component {
    constructor(props) {
        super(props);

    }

    onSubmit = () => {
        let {columns, data, columnNameField, dataFieldName, generateReport, reportName} = this.props;
        let myReportMap = {};
        for (let column of columns){
            myReportMap[column[columnNameField]] = column[dataFieldName];
        }
        generateReport(myReportMap, reportName, data);
    }

    render() {
        return (
            <div>
                <Button variant="primary" onClick={this.onSubmit}>Download Table</Button>
            </div>
        );
    }
}

export const TableExport = connect(null, {generateReport})(TableExportComponent);

TableExport.propTypes = {
    columns: PropTypes.array.isRequired,
    data: PropTypes.array.isRequired,
    columnNameField: PropTypes.string.isRequired,
    dataFieldName: PropTypes.string.isRequired,
    reportName: PropTypes.string.isRequired
}