/**
 * Created by shivaraman on 6/3/17.
 */
import React from 'react';
import {change, Field, reduxForm, SubmissionError} from 'redux-form';
import {connect} from 'react-redux';
import PropTypes from "prop-types";
import {loginUser} from '../../actions/auth_actions';
import {fetchOrgs} from "../../actions/org_actions";
import {FieldRBInputSrOnly} from "../../components/redux_form/field_components_rb";
import Alert from "react-bootstrap/Alert";
import Button from "react-bootstrap/Button";
//import loginbg1 from "../../../assets/images/tru-vetting-bg1.png";

class Login extends React.Component {

    constructor(props) {
        super(props);
    }

    onSubmit(values) {
        return this.props.loginUser(values, this.props.onSuccess, error => {
            let errorMessage = 'Username or password was incorrect!';
            console.log('Error logging in', error)
            if (error){
                if (error.response && error.response.data) {
                    errorMessage = error.response.data;
                } else {
                    errorMessage = "Server error occurred";
                }
            }
            this.props.dispatch(change('LoginForm', 'password', ''));// This is a redux-form function that clears form values.
            // Redux-form async validation error
            throw new SubmissionError({
                _error: errorMessage,
                username: 'Check Username!',
                password: 'Check Password'
            });
        });
    }

    render() {
        const {handleSubmit, error} = this.props; // is the same as const handleSubmit=this.props.handleSubmit
        return (

            <div className='reporting_bg p-0'>
     <div className='col-lg-12 row m-0 p-0 h-100 align-items-center'>
                {/* <div className='col-lg-6 p-0'>
                <img src={loginbg} className="login_bg_"></img>
                </div> */}


                <form className="form-signin col-sm-12 col-lg-3 col-md-6" onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                    <div className='heading_container'>
                        <h3 style={{background: "transparent"}}>User Login</h3>
                           {/* <h2 className="form-signin-heading">Please Log In</h2> */}
                    </div>
                 
                    {error && <Alert variant="danger" className='alert-danger'>{error}</Alert>}
                    <div className='col-lg-12'>
                        <div className='row mb-4'>
                            <label className='form-label fs-6 fw-bolder text-gray-900'>Username</label>
                            <Field name="username" label="Username" component={FieldRBInputSrOnly} type="text" required
                           autoFocus/>
                        </div>

                        <div className='row mb-4'>
                        <label className='form-label fs-6 fw-bolder text-gray-900'>Password</label>
                        <Field name="password" label="Password" component={FieldRBInputSrOnly} type="password"
                           required/>
                        </div>
                    </div>
                    
                    
                    <Button variant="primary" size="lg" block type="submit" className='w-100'>Login</Button>
                </form>
               
            </div>
            </div>

       
            
        );
    }
}

Login.propTypes = {
    onSuccess: PropTypes.func.isRequired
};

function validate(values) {
    const errors = {}

    // validate
    if (!values.username) {
        errors.username = "Username is required!";
    }
    // if the errors is empty all is well
    return errors;
}

export default reduxForm({
    validate, // same as validate:validate
    form: 'LoginForm',
    initialValues: {
        password: '',
    }
})(
    connect(null, {loginUser, fetchOrgs})(Login)
);