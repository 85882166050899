/**
 * Created by pshivaraman on 6/21/18.
 */

import React from "react";
import Form from "react-bootstrap/Form"
import Row from "react-bootstrap/Row";

export function getErrorMsg(field) {
    return field.meta.touched ? (
        field.meta.error ? field.meta.error :
            field.meta.warning ? field.meta.warning :
                field.meta.success ? field.meta.success : ''
    ) : '';
}

export function getValidationState(field) {
    return {
        isInvalid: field.meta.touched && field.meta.error,
        isValid: field.meta.touched && field.meta.success
    };
}

export function getFormGroupAs(field){
    return field.horizontal ? Row : "div";
}

export function getControlLabel(field) {
    return field.horizontal ?
        <Form.Label column  className={field.required ? "required" : ""}>
            {field.label}
        </Form.Label> :
        <Form.Label className={field.required ? "required" : ""}>{field.label}</Form.Label>;
}