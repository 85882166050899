/**
 * Created by pshivaraman on 7/27/18.
 */

import React, {Component, Fragment} from "react";
import {SubmissionError} from "redux-form";
import {FieldRBSelectWithKeys} from "../redux_form/field_components_rb";
import {showSuccessGrowl} from "../../actions/msg_actions";
import {fetchOrgAdminAssignableRole, updateUserRole, updateUserRoleForOrg,fetchUsersByOrg} from "../../actions/user_actions";
import {connect} from "react-redux";
import {CUR_ORG} from "../../actions/session_constants";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";
import {Formik, Field} from "formik";
import * as Yup from "yup";
import {ButtonToolbar, Card, Form} from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import {CommonUtils} from "../../actions/common_utils";
import {FormikFieldDualListBox} from "../formik/formik_field_duallistbox";
import {fetchSubjectForUserCreation} from "../../actions/subject_actions";
import {getActiveOrgContractsAsync} from "../../actions/contract_actions";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";

class UserRoleModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false
        }
    }

    componentDidMount() {
        this.fetchAvailRoleTypes();
        this.fetchAvailContracts();
    }

    fetchAvailRoleTypes = async () => {
        let availRoles = await fetchOrgAdminAssignableRole(
            (error) => {
                this.props.showErrorGrowl("Cannot fetch roles available", error.message);
            }
        );
        this.setState({
            availRoles
        })
    }

    fetchAvailContracts = async () => {
        let availContractObjects = await getActiveOrgContractsAsync(
            (error) => {
                this.props.showErrorGrowl("Cannot fetch contracts available", error.message);
            }
        );
        let availContracts = [];
        availContractObjects.forEach((eachContract) => {
            availContracts.push({
                value: eachContract.id,
                label: eachContract.name
            })
        });

        this.setState({
            availContracts
        })
    }

    open() {
        this.setState({
            showModal: true
        });
    }

    onAssignRole = (values) => {
        const {user} = this.props;
        values.personId = user.subject.id;
        values.adminUserCreation = this.state.createAdminUser;
        values.userName = user.userName;
        console.log('Assign role ', values);
        return this.props.updateUserRole(values, () => {
            // Success Response
            this.props.showSuccessGrowl("User Updated", "User was updated successfully");
            this.props.fetchUsersByOrg();
            this.close();
        }, (message) => {
            // Failure Response
            throw new SubmissionError({
                _error: message
            });
        });
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    isSelectedRoleContractRelated = (values) => {
        if (values.roleType.startsWith('CONTRACT')){
            return true;
        } else {
            return false;
        }
    }

    userFormAssignRole() {
        const {availRoles} = this.state;
        const {user} = this.props;

        return (
            <Formik
                initialValues={{
                    roleType: this.props.role,
                    contracts: []
                }}
                enableReinitialize={true}
                onSubmit={this.onAssignRole}
                validationSchema={
                    Yup.object().shape({})
                }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur})=> (

                    <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                        <Card>
                            <Card.Body>
                                <Row>
                                    <Form.Group as={Col} md="12">
                                        <Form.Label>User {user.userName} exists. Please assign role</Form.Label>
                                        <Form.Control required as="select" name={'roleType'}
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      placeholder={'Select a Role'}
                                                      isInvalid={touched.roleType && !!errors.roleType}
                                                      value={values.roleType}
                                        >
                                            {CommonUtils.getOptionsForSelect(CommonUtils.forDropDown(availRoles))}
                                        </Form.Control>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.roleType}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    {this.isSelectedRoleContractRelated(values) &&
                                    <Form.Group as={Col} md="8" controlId={'contracts'}>
                                        <Form.Label>Associated Contracts</Form.Label>
                                        <Field
                                            id="contracts"
                                            name="contracts"
                                            component={FormikFieldDualListBox}
                                            options={this.state.availContracts}
                                        />
                                        <Form.Control.Feedback type="invalid">
                                            {errors.contracts}
                                        </Form.Control.Feedback>
                                    </Form.Group>
                                    }
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <ButtonToolbar>
                                    <DeleteConfirmationModal buttonText={"Cancel"}  disabled={isSubmitting} actionText={"cancel"} deleteEntity="Create User"
                                                  handleDeleteCallback={this.close}/>
                                    <Button onClick={this.previousPage}>Previous</Button>
                                    <Button variant="primary" type="submit"
                                            disabled={isSubmitting}>Assign Role</Button>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>

                    </Form>
                )}

            </Formik>
        );
    }

    render() {
        const {showModal} = this.state;

        return (
            <Fragment>
                {ConditionalDisplay(
                    <Button variant="link" onClick={this.open} className={"close"}>
                        <span aria-hidden="true" title={"Edit User Roles"} aria-label="Edit User Roles">
                            <i className="fa fa-square-pen" />
                        </span>
                    </Button>
                )}
                <Modal show={showModal} onHide={this.close} size={'lg'} scrollable={true}>
                    {this.userFormAssignRole()}
                </Modal>
            </Fragment>
        )
    }
}

export default connect(null, {showSuccessGrowl, updateUserRole,fetchUsersByOrg})(UserRoleModal);