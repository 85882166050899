/**
 * Created by pshivaraman on 7/26/18.
 */

import React from "react";

export const FieldInvisibleInput = (field) => {
    return (
        <input {...field.input} style={{display: "none"}} />
    )
};