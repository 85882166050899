/**
 * Created by pshivaraman on 7/26/18.
 */

import React, {Component} from "react";
//import {FieldRBDatePicker, FieldRBInput, FieldRBMaskedInput} from "../redux_form/field_components_rb";
import {Button, ButtonToolbar, Card, Col, Form, Row} from "react-bootstrap";
import {connect} from "react-redux";
import {addOrgToSubject, fetchPerson, fetchSubjectBySocial} from "../../actions/subject_actions";
import PropTypes from "prop-types";
import Alert from "react-bootstrap/Alert";
import {fetchSysUserName} from "../../actions/user_actions";
import {Formik} from "formik";
import * as Yup from "yup";
import MaskedInput from "react-input-mask";

class SubjectSearchModalForSys extends Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.state = {
            showModal: false,
            values: {},
            userObj: null,
        }
    }

    close = async() => {
        const {onClose} =this.props;
        this.setState({subject:null})
        onClose();
    }

    onSubmit = async (values) => {
        let {subject} = this.state;
        if (subject && subject.id){
            this.onFinish(subject);
        } else {
            this.setState({noSubjectFound: false});

            console.log('Searching values are ', values)
            try {
                const subject = await fetchSubjectBySocial(values.ssn);
                this.setState({subject})
            } catch (error) {
                console.log('Errory is ', error);
                if (error.response.status === 404) {
                    this.setState({noSubjectFound: true});
                }
            }
        }
    }

    getUserName = async (subject) => {
        console.log(subject);
        let uName = await fetchSysUserName(subject.id,
            (error) => {
                console.log('Error is ', error);
            }
        );
        return uName;
    }

    onFinish = async (subject) => {
        console.log("Finishing")
        const {onSelect} = this.props;

        if (subject) {
            let userObj = await this.getUserName(subject);
            onSelect(subject, userObj);
        }
    }

    getResult() {
        const {dirty, valid, anyTouched} = this.props;
        //this.setSubjectInState();
        const {subject} = this.state;
        const {noSubjectFound} = this.state;
        if (noSubjectFound === true) {
            console.log("Returning subject not found error");
            return <Alert variant="danger">No Subject Found</Alert>;
        } else if (subject && subject.id) {
            console.log("Subject has been found", subject.personFirstName);

            return <Alert variant="success">Found {subject.personFirstName} {subject.personLastName}</Alert>;
        } else {
            return <Alert variant="warning">Please search for a subject</Alert>
        }
    }

    onKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault(); // Not allowing this, since it causes issues with selecting subject. Click the Search button instead
        }
    }

    render() {
        const {subject} = this.state;

        return (
            <Formik
                    initialValues={{
                        "ssn": ""
                    }}
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                    validationSchema={
                        Yup.object().shape({
                            ssn: Yup.string().required('SSN is required').test(
                                'required-ssn-test',
                                'Please enter a valid SSN',
                                function (value) {
                                    return value && /^[0-9a-z-A-Z][0-9]{2}-[0-9]{2}-[0-9]{4}$/.test(value);
                                }
                            )
                        })
                    }
            >
                {({isSubmitting, values, handleChange, setFieldValue, setFieldTouched, handleSubmit, touched, resetForm, errors, handleBlur})=> (

                    <Form noValidate onSubmit={handleSubmit} onKeyPress={this.onKeyPress}>
                        <Card>
                            <Card.Header>Subject Search</Card.Header>
                            <Card.Body>
                                <Row>
                                    <Form.Group as={Col} md="6" controlId={'ssn'}>
                                        <Form.Control required type="text" name={'ssn'}
                                                      as={MaskedInput}
                                                      mask="*99-99-9999"
                                                      onChange={handleChange}
                                                      onBlur={handleBlur}
                                                      isInvalid={touched.ssn && !!errors.ssn}
                                                      value={values.ssn}/>
                                        <Form.Control.Feedback type="invalid">
                                            {errors.ssn}
                                        </Form.Control.Feedback>

                                    </Form.Group>
                                    <Form.Group as={Col} md="6" controlId={'resultText'}>
                                        {this.getResult()}
                                    </Form.Group>
                                </Row>
                            </Card.Body>
                            <Card.Footer>
                                <ButtonToolbar>
                                    <Button onClick={this.close}>Cancel</Button>
                                    <Button variant="primary" type="submit"
                                            disabled={isSubmitting}>{subject && subject.id?"Select":"Search"}</Button>
                                </ButtonToolbar>
                            </Card.Footer>
                        </Card>

                    </Form>
                )}

            </Formik>


        )
    }
}

SubjectSearchModalForSys.propTypes = {
    buttonText: PropTypes.string,
    onSelect: PropTypes.func.isRequired,
    sysAdminUserCreation: PropTypes.bool
};

export default connect(null, {fetchPerson, addOrgToSubject})(SubjectSearchModalForSys);