/**
 * Created by pshivaraman on 9/7/18.
 */

import React, {Component, Fragment} from "react";
import {assignManagingOrg, fetchAllUnmanagedOrgs} from "../../actions/org_actions";
import {connect} from "react-redux";
import debounce from "lodash/debounce";
import ListGroup from "react-bootstrap/ListGroup";
import ListGroupItem from "react-bootstrap/ListGroupItem";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";

class ManagedOrgModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            showModal: false,
            name: ""
        }
    }

    findUnmanagedOrgsByName = debounce(() => {
        // TODO: Implement
        //this.props.fetchUnmanagedOrgsByName(this.state.name);
    }, 300);

    onNameChange(event) {
        this.setState({
            name: event.target.value,
            cageCode: ""
        });
        this.findUnmanagedOrgsByName();
    }

    open() {
        this.setState({
            showModal: true,
            name: "",
            cageCode: ""
        });
        this.props.fetchAllUnmanagedOrgs();
    }

    onAssign(managedOrgId) {
        const {assignManagingOrg, managingOrgId} = this.props;

        assignManagingOrg(managingOrgId, managedOrgId, () => {
            this.props.fetchAllUnmanagedOrgs();
        });
    }

    close() {
        this.setState({
            showModal: false
        });
        this.props.onFinish();
    }

    getResult() {
        const {orgs, managingOrgId} = this.props;

        // Filter current Org
        const orgItems = orgs.filter((org) => org.id !== managingOrgId)
            .map(org => {
                return (
                    <ListGroupItem key={org.org.id}>
                        {org.name} <Button onClick={() => this.onAssign(org.id)}>Assign</Button>
                    </ListGroupItem>
                )
            });
        return (
            <ListGroup>
                {orgItems}
            </ListGroup>
        );
    }

    render() {
        const {showModal} = this.state;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>Assign Managed Orgs</Button>
                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Assign Managed Organizations</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Form>
                            <Form.Group>
                                <Form.Label>Name</Form.Label>
                                <Form.Control type="text" placeholder="Type to begin searching..."
                                             value={this.state.name}
                                             onChange={this.onNameChange.bind(this)} disabled/>
                            </Form.Group>
                        </Form>

                        {this.getResult()}
                    </Modal.Body>
                    <Modal.Footer>
                        <Button onClick={this.close}>Finished</Button>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

function mapStateToProps({orgs_search}) {
    return {
        orgs: orgs_search
    }
}

export default connect(mapStateToProps, {fetchAllUnmanagedOrgs, assignManagingOrg})(ManagedOrgModal)