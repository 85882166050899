import React, {Component, Fragment} from "react";
import {reduxForm} from "redux-form";
import {connect} from "react-redux";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropTypes from "prop-types";
import {ForeignTravelDetailComponent} from "./foreign_travel_detail_component";
import {cancelForeignTravel, reportingSecondFactor} from "../../actions/subject_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import {REPORTING_PASS_CODE, VERIFIED_EMAIL} from "../../actions/auth_actions";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";
import {Link} from "react-router-dom";


class SubjectForeignTravelDetailsModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);

        this.state = {
            showModal: false
        }
    }

    open() {
        this.setState({
            showModal: true
        });
    }

    cancel = async () => {
        try {
            const {foreignTravelDetail} = this.props;
            await cancelForeignTravel(foreignTravelDetail.id);
            this.props.showSuccessGrowl("Foreign Travel Cancelled", "Your foreign travel has been cancelled and removed from your records");
            this.setState({
                showModal: false
            });
            let emailReq = {
                emailAddress: sessionStorage.getItem(VERIFIED_EMAIL),
                passCode: sessionStorage.getItem(REPORTING_PASS_CODE)
            }
            this.props.reportingSecondFactor(emailReq);

        } catch (error){
            console.log('Error is ', error)
            this.props.showErrorGrowl("Unable to cancel", error.response.data.message);
        }
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    render() {
        const {showModal} = this.state;
        const {foreignTravelDetail, selectedContactId = "", allowCancel} = this.props;

        return (
            <Fragment>
                <Button onClick={this.open} variant={"link"} className={"close"}>
                     <span aria-hidden="true" title={"Update"}
                           aria-label="Remove User">
                        <i className="fa fa-circle-info"/>
                     </span>
                </Button>

                <Modal show={showModal} onHide={this.close} size={"lg"}>
                    <Modal.Header closeButton>
                        <Modal.Title>Foreign Travel Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ForeignTravelDetailComponent foreignTravelDetail={foreignTravelDetail}/>
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar>
                            {allowCancel &&
                            <DeleteConfirmationModal buttonText={"Delete"} actionText={"delete"} deleteEntity="Foreign Travel"
                                                     handleDeleteCallback={this.cancel}/>
                            }
                            {allowCancel && foreignTravelDetail.id &&
                            <Link className="btn btn-primary" to={`/reporting/subject/foreignTravel/edit/${foreignTravelDetail.id}`}>Edit</Link>
                            }
                            <Button variant="primary" onClick={this.close}>Close</Button>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

SubjectForeignTravelDetailsModal.propTypes = {
    foreignTravelDetail: PropTypes.object.isRequired,
    selectedContactId: PropTypes.object,
    allowCancel: PropTypes.bool
};

export default reduxForm({
    form: 'OrgUserRoleForm'
})(
    connect(null, {showErrorGrowl, showSuccessGrowl, reportingSecondFactor})(SubjectForeignTravelDetailsModal)
);