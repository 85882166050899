/**
 * Created by pshivaraman on 7/2/18.
 */

import React, {Fragment} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {FieldRBDualListBox} from "../redux_form/field_components_rb";
import {Field, reduxForm} from "redux-form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import {ConditionalDisplay} from "../../containers/util/shouldDisplay";

class AddSubjectModal extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);

        this.state = {
            showModal: false
        }
    }

    open() {
        this.setState({showModal: true});
        // TODO: Make asynchronous
        this.props.searchFunction(".*", () => {
            // Nothing for now.
        });
    }

    close() {
        this.setState({showModal: false});
    }

    onSubmit(values) {
        this.props.onSubjectsAdd(values.selectedSubjects);
        this.close();
    }

    getAvailSubjects() {
        const {subjects, existingSubjects} = this.props;
        //const {sel_subjects} = this.state;
        if (subjects) {
            //return subjects.filter((subject) => {
            //const findFilter = (sub) => subject.personObject.id === sub.personObject.id;
            //return !sel_subjects.find(findFilter) && (!existingSubjects || !existingSubjects.find(findFilter));
            //});
            return subjects.map(subject => {
                return {
                    value: subject.id,
                    label: `${subject.personFirstName} ${subject.personLastName} (${subject.socialSecurityNumber})`
                }
            })
        }
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit} = this.props;

        return (
            <Fragment>
                {ConditionalDisplay(
                    <Button onClick={this.open} variant="primary">Add Subjects</Button>
                )}

                <Modal show={showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Subjects</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Body>
                            <Field name="selectedSubjects" label="Subjects to Add" component={FieldRBDualListBox}
                                   options={this.getAvailSubjects()} canFilter={true}/>
                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit">Add Subjects</Button>
                                <Button variant="danger" onClick={this.close}>Cancel</Button>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

AddSubjectModal.propTypes = {
    onSubjectsAdd: PropTypes.func.isRequired,
    searchFunction: PropTypes.func.isRequired,
    existingSubjects: PropTypes.arrayOf(PropTypes.object),
};

function mapStateToProps({subject_results}) {
    return {
        subjects: subject_results
    }
}

export default reduxForm({
    form: 'AddSubjectForm'
})
(connect(mapStateToProps, null)(AddSubjectModal));