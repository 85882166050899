import React, {Component, Fragment} from 'react';
import {connect} from 'react-redux';
import {VERIFIED_EMAIL, REPORTING_PASS_CODE, refreshSelfReportingPassCode} from '../../actions/auth_actions';
import {getSelfReportingExpirationTime} from "../../actions/fieldValue_actions";
import {SessionTimeoutModal} from "../../components/session_timeout_modal";

export default function (ComposedComponent) {
    class Authentication extends Component {

        componentWillMount() {
            this.validateAuth();
        }

        validateAuth() {
            if (!sessionStorage.getItem(VERIFIED_EMAIL)) {
                this.props.history.push('/reporting/subject/emailCheck');
            } else if (!sessionStorage.getItem(REPORTING_PASS_CODE)) {
                // Need to show second factor for email
                this.props.history.push('/reporting/subject/secondFactor');
            }
        }

        // componentWillUpdate(nextProps) {
        //     this.validateAuth();
        // }

        render() {
            return (
                <Fragment>
                    <ComposedComponent {...this.props} />
                    <SessionTimeoutModal
                        tokenValue={sessionStorage.getItem(REPORTING_PASS_CODE)}
                        getTokenExpiry={getSelfReportingExpirationTime}
                        refreshTokenFunction={() => refreshSelfReportingPassCode()}
                        />
                </Fragment>
            );
        }
    }

    function mapStateToProps(state) {
        return {selfReportingVerified: state.selfReportingVerified};
    }

    return connect(mapStateToProps)(Authentication);
}

