import React, {Component, Fragment} from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import PropTypes from "prop-types";
import {QuestionnaireResponses} from "./questionnaire_travel_contact";
import filterFactory, {textFilter} from "react-bootstrap-table2-filter";
import BootstrapTable from "react-bootstrap-table-next";

export class SubjectForeignContactQuestionnaireModal extends Component {
    constructor(props) {
        super(props);

        this.state = {
            showModal: false
        }
    }

    open = () => {
        this.setState({
            showModal: true
        });
    }

    close = () => {
        this.setState({
            showModal: false
        });
    }

    render() {
        const {questionnaire, selectedContactId = ""} = this.props;
        const {showModal} = this.state;

        const columns = [{
            dataField: 'firstName',
            text: 'First Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'lastName',
            text: 'Last Name',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'citizenCountry',
            text: 'Citizenship Country',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'placeOfContact',
            text: 'Place of Contact',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'startDate',
            text: 'Contact Initiated Date',
            sort: true,
            filter: textFilter()
        }, {
            dataField: 'contactReason',
            text: 'Reason for Contact',
            sort: true,
            filter: textFilter()
        }];

        // Applies different classes to rows
        const rowClasses = (row, rowIndex) => {
            if (row.id === selectedContactId){
                return "table-success";
            }
            return "";
        }

        return (
            <Fragment>
                <Button onClick={this.open} variant={"link"} className={"close"}>
                     <span aria-hidden="true" title={"Update"}
                           aria-label="Remove User">
                        <i className="fa fa-circle-info"/>
                     </span>
                </Button>

                <Modal show={showModal} onHide={this.close} size={"lg"}>
                    <Modal.Header closeButton>
                        <Modal.Title>Foreign Contact Questionnaire Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <h3>Contacts List</h3>
                        <BootstrapTable bootstrap4={true} hover striped bordered={false} keyField="id"
                                        data={questionnaire.contacts}
                                        columns={columns}
                                        filter={filterFactory()}
                        rowClasses={rowClasses}/>

                        <h3>Quesionnaire Responses</h3>
                        <QuestionnaireResponses questionnaireResp={questionnaire} />
                    </Modal.Body>
                    <Modal.Footer>
                        <ButtonToolbar>
                            <Button type="button" onClick={this.close}>Close</Button>
                        </ButtonToolbar>
                    </Modal.Footer>
                </Modal>
            </Fragment>
        )
    }
}

SubjectForeignContactQuestionnaireModal.propTypes = {
    questionnaire: PropTypes.object.isRequired,
    selectedContactId: PropTypes.object
}