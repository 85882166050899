/**
 * Created by pshivaraman on 3/28/18.
 */

import React from "react";
import {Field, Formik, withFormik} from "formik";
import * as Yup from "yup";
import {connect} from "react-redux";
import {createOrg, fetchOrgs} from "../../actions/org_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Col from "react-bootstrap/Col";
import ButtonToolbar from "react-bootstrap/ButtonToolbar";
import Form from "react-bootstrap/Form";
import {
    getComplianceDocTypesForSubject,
    getDocTypesByEntityType,
    getReqDocsByEntityType
} from "../../actions/fieldValue_actions";
import MaskedInput from "react-input-mask";
import {CountryDropdown, RegionDropdown} from "react-country-region-selector";
import {FormikFieldDropzone} from "../formik/formik_field_dropzone";
import {FormikFieldDualListBox} from "../formik/formik_field_duallistbox";
import DeleteConfirmationModal from "../../components/delete_confirm_modal";

class OrgNew extends React.Component {
    constructor(props) {
        super(props);

        this.close = this.close.bind(this);
        this.open = this.open.bind(this);

        this.state = {
            availSubjectDocTypes: [],
            availFacilityDocTypes: []
        } 
    }

    open() {
        this.props.setStatus({showModal: true});
        getComplianceDocTypesForSubject((response) => {
            this.setState({
                availSubjectDocTypes: response.data.map(docType => {
                    return {
                        value: docType.id,
                        label: docType.type
                    }
                })
            });
        });
        getReqDocsByEntityType("SUBJECT").then((response) => {
            this.props.setFieldValue("subjectRequiredDocs", response.data.map(docType => docType.id), false);
        });
        getDocTypesByEntityType("FACILITY", (response) => {
            this.setState({
                availFacilityDocTypes: response.data.map(docType => {
                    return {
                        value: docType.id,
                        label: docType.type
                    }
                })
            });
        });
        getReqDocsByEntityType("FACILITY").then((response) => {
            this.props.setFieldValue("facilityRequiredDocs", response.data.map(docType => docType.id), false);
        });
    }

    close() {
        this.props.setStatus({
            showModal: false
        });
        this.props.resetForm();
    }
    render() {
        const {status = {showModal: false}, values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting} = this.props;

        return (
            <React.Fragment>
                <Button onClick={this.open}><i className="fa fa-plus"></i> Create Organization</Button>

                <Modal size="lg" show={status.showModal} onHide={this.close}>
                    <Modal.Header closeButton>
                        <Modal.Title>Create Organization</Modal.Title>
                    </Modal.Header>
                    <form onSubmit={handleSubmit} noValidate>
                        <Modal.Body>
                           <Form.Row>
                                <Form.Group as={Col} md={4} controlId="orgNewName">
                                    <Form.Label>Organization Name</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Organization Name"
                                        isInvalid={touched.name && !!errors.name}
                                        value={values.name}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.name}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={4} controlId="orgNewEin">
                                    <Form.Label>Tax ID Number (TIN)</Form.Label>
                                    <Form.Control
                                        required
                                        as={MaskedInput}
                                        name="ein"
                                        mask="99-9999999"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        isInvalid={touched.ein && !!errors.ein}
                                        value={values.ein}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.ein}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={4} controlId="orgNewDescription">
                                    <Form.Label>Organization Description</Form.Label>
                                    <Form.Control
                                        type="text"
                                        name="description"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Description"
                                        isInvalid={touched.description && !!errors.description}
                                        value={values.description}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.description}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>


                            <div className="create_org">
                            <div className="section_title mb-20">
                            <h4>Primary Address</h4>
                            </div>
                            
                            <Form.Group controlId="orgNewStreet">
                                <Form.Label>Street Address</Form.Label>
                                <Form.Control
                                    required
                                    type="text"
                                    name="street"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="Street Address"
                                    isInvalid={touched.street && !!errors.street}
                                    value={values.street}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.street}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Row>
                                <Form.Group as={Col} md={4} controlId="orgNewCity">
                                    <Form.Label>City</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="city"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="City"
                                        isInvalid={touched.city && !!errors.city}
                                        value={values.city}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.city}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={3} controlId="orgNewCountry">
                                    <Form.Label>Country</Form.Label>
                                    <CountryDropdown
                                        required
                                        valueType="short"
                                        labelType="full"
                                        name="country"
                                        onChange={(value, e) => handleChange(e)}
                                        onBlur={handleBlur}
                                        value={values.country}
                                        classes={`form-control${touched.country && !!errors.country ? " is-invalid" : ""}`}
                                        priorityOptions={["US"]}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.country}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={3} controlId="orgNewState">
                                    <Form.Label>State or Region</Form.Label>
                                    <RegionDropdown
                                        valueType="short"
                                        labelType="full"
                                        countryValueType="short"
                                        country={values.country}
                                        name="state"
                                        onChange={(value, e) => handleChange(e)}
                                        onBlur={handleBlur}
                                        classes={`form-control${touched.state && !!errors.state ? " is-invalid" : ""}`}
                                        value={values.state}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.state}
                                    </Form.Control.Feedback>
                                </Form.Group>
                                <Form.Group as={Col} md={2} controlId="orgNewZipCode">
                                    <Form.Label>Postal Code</Form.Label>
                                    <Form.Control
                                        required
                                        type="text"
                                        name="zipcode"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        placeholder="Postal Code"
                                        isInvalid={touched.zipcode && !!errors.zipcode}
                                        value={values.zipcode}
                                    />
                                    <Form.Control.Feedback type="invalid">
                                        {errors.zipcode}
                                    </Form.Control.Feedback>
                                </Form.Group>
                            </Form.Row>
                            </div>

                            <div className="create_org">
                            <div className="section_title mb-20">
                                <h4>Point of Contact Information</h4>
                            </div>

                            <Form.Row>
                            <Form.Group as={Col} md={4} controlId="orgNewPocName">
                                <Form.Label>POC Name</Form.Label>
                                <Form.Control
                                    type="text"
                                    name="pocName"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="POC Name"
                                    isInvalid={touched.pocName && !!errors.pocName}
                                    value={values.pocName}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.pocName}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={4} controlId="orgNewPocPhone">
                                <Form.Label>POC Phone Number</Form.Label>
                                <Form.Control
                                    type="tel"
                                    name="pocPhone"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="POC Phone Number"
                                    isInvalid={touched.pocPhone && !!errors.pocPhone}
                                    value={values.pocPhone}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.pocPhone}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group as={Col} md={4} controlId="orgNewPocEmail">
                                <Form.Label>POC Email Address</Form.Label>
                                <Form.Control
                                    type="email"
                                    name="pocEmail"
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    placeholder="POC Email Address"
                                    isInvalid={touched.pocEmail && !!errors.pocEmail}
                                    value={values.pocEmail}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.pocEmail}
                                </Form.Control.Feedback>
                            </Form.Group>
                            </Form.Row>
                           
                            </div>

                            <div className="create_org">
                            <div className="section_title mb-20">
                                <h4>Disable Email Communication</h4>
                            </div>
                            
                            <Form.Group>
                                <Form.Check
                                    name="doNotAllowEmailNotificationWithinOrg"
                                    label="Disable Email communication to anyone in the Org"
                                    onChange={handleChange}
                                    isInvalid={touched.doNotAllowEmailNotificationWithinOrg && !!errors.doNotAllowEmailNotificationWithinOrg}
                                    feedback={errors.doNotAllowEmailNotificationWithinOrg}
                                    id="orgNewDisableEmailAlerts"
                                />
                            </Form.Group>
                            </div>

                            <div className="create_org">
                            <div className="section_title mb-20">
                                <h4>Compliance Requirements</h4>
                            </div>
                            
                            <Form.Group>
                                <Form.Label>Subject Compliance</Form.Label>
                                <Field
                                    id="orgNewSubjectRequiredDocs"
                                    name="subjectRequiredDocs"
                                    component={FormikFieldDualListBox}
                                    options={this.state.availSubjectDocTypes}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.subjectRequiredDocs}
                                </Form.Control.Feedback>
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>Location Compliance</Form.Label>
                                <Field
                                    id="orgNewFacilityRequiredDocs"
                                    name="facilityRequiredDocs"
                                    component={FormikFieldDualListBox}
                                    options={this.state.availFacilityDocTypes}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {errors.facilityRequiredDocs}
                                </Form.Control.Feedback>
                            </Form.Group>
                            </div>

                            <div className="create_org">
                            <div className="section_title mb-20">
                                <h4>Logo</h4>
                            </div>
                            <Form.Group controlId="orgNewLogo">
                                <Form.Label>Logo</Form.Label>
                                <Field name="logoDoc" component={FormikFieldDropzone}/>
                            </Form.Group>
                            </div>

                        </Modal.Body>
                        <Modal.Footer>
                            <ButtonToolbar>
                                <Button variant="primary" type="submit" disabled={isSubmitting}>Create</Button>
                                {/* <Button variant="danger" onClick={this.close}
                                        disabled={isSubmitting}>Cancel</Button> */}
                                         <DeleteConfirmationModal buttonText={"Cancel"} actionText={"cancel"} deleteEntity="Create Organization"
                                                  handleDeleteCallback={this.close}/>
                            </ButtonToolbar>
                        </Modal.Footer>
                    </form>
                </Modal>
            </React.Fragment>
        )
    }
}

async function onSubmit(values, {props, resetForm, setFieldError, setStatus}) {
    if (props.managingOrgId) {
        values.type = "MANAGED";
        values.managingOrgId = props.managingOrgId;
    } else {
        values.type = "ADMIN";
    }

    try {
        await props.createOrg(values);

        // Success Response
        props.showSuccessGrowl("Organization Created", "Organization was created successfully");
        props.onCreate();
        resetForm();
        setStatus({
            showModal: false
        });
    } catch (errorMsg) {
        values.doNotAllowEmailNotificationWithinOrg = (values.doNotAllowEmailNotificationWithinOrg ? 'Yes' : 'No');
        props.showErrorGrowl("Organization Creation Failed", errorMsg, true, 5000);
        setFieldError("ein", "Organization Exists!");
        throw new Error("Organization Exists!");
    }
}

const validateSchema = Yup.object().shape({
    name: Yup.string()
        .required("Name is required"),
    ein: Yup.string()
        .required("Tax ID Number is required"),
    street: Yup.string()
        .required("Street Address is required"),
    city: Yup.string()
        .required("City is required"),
    country: Yup.string()
        .required("Country is required"),
    zipcode: Yup.string()
        .required("Postal or Zip Code is required"),
    pocEmail: Yup.string()
        .email("POC Email must be a valid email!")
});

export default connect(null, {createOrg, fetchOrgs, showSuccessGrowl, showErrorGrowl})(
    withFormik(
        {
            mapPropsToValues: (props) => {
                return {
                    name: "",
                    ein: "",
                    description: "",
                    street: '',
                    city: '',
                    state: '',
                    country: 'US',
                    zipcode: "",
                    pocName: '',
                    pocPhone: '',
                    pocEmail: '',
                    subjectRequiredDocs: [],
                    facilityRequiredDocs: [],
                    logoDoc: [],
                    doNotAllowEmailNotificationWithinOrg: false
                }
            },
            mapPropsToStatus: (props) => {
                return {
                    showModal: false
                }
            },
            validationSchema: (props) => validateSchema,
            handleSubmit: onSubmit
        }
    )(OrgNew)
);