/**
 * Created by pshivaraman on 7/27/18.
 */

import React, {Component, Fragment} from "react";
import {
    FieldRBCountrySelector,
    FieldRBDatePicker,
    FieldRBInput,
    FieldRBMaskedInput,
    FieldRBSelect,
    FieldRBSelectWithKeys
} from "../redux_form/field_components_rb";
import {Field, reduxForm, SubmissionError} from "redux-form";
import {connect} from "react-redux";
import {createPerson} from "../../actions/subject_actions";
import {fetchOrgContracts} from "../../actions/contract_actions";
import PropTypes from "prop-types";
import {getBriefingLevels, getEmployeeTypes} from "../../actions/fieldValue_actions";
import {showErrorGrowl, showSuccessGrowl} from "../../actions/msg_actions";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Alert from "react-bootstrap/Alert";
import {EMAIL_VALIDATION_STR} from "../../actions/common_utils";
import {Form} from "react-bootstrap";
import Col from "react-bootstrap/Col";

class SubjectNewModal extends Component {
    constructor(props) {
        super(props);

        this.open = this.open.bind(this);
        this.close = this.close.bind(this);
        this.state = {
            showModal: false,
            // briefingLevels: [],
            employeeTypes: []
        }
    }

    open() {
        const {subjectPrefill} = this.props;
        if (subjectPrefill) {
            this.props.change("socialSecurityNumber", subjectPrefill.ssn);
        }
        // getBriefingLevels().then(response => {
        //     console.log('Response is ', response.data);
        //     this.setState({
        //         briefingLevels: ["", ...response.data]
        //     });
        // });

        getEmployeeTypes().then(response => {
            this.setState({
                employeeTypes: response.data
            });
        });
        this.setState({
            showModal: true
        });
    }

    close() {
        this.setState({
            showModal: false
        });
    }

    onSubmit(values) {
        this.props.createPerson(values, () => {
            this.props.onCreate(values.socialSecurityNumber);
            this.props.showSuccessGrowl("Subject Created", "Subject Created Successfully");
            this.close();
        }, (error) => {
            if (error.response.status === 400) {
                this.props.showErrorGrowl("Subject Exists", "The SSN provided already belongs to a subject!");
                // throw new SubmissionError({
                //     _error: "SSN Already Belongs to Subject",
                //     socialSecurityNumber: "This SSN has already been used"
                // })
            }
        });
    }

    render() {
        const {showModal} = this.state;
        const {handleSubmit, buttonText, error} = this.props;

        return (
            <Fragment>
                <Button variant="primary" onClick={this.open}>{buttonText ? buttonText : "Create Subject"}</Button>
                <Modal show={showModal} size="md" onHide={this.close}>
                    <form onSubmit={handleSubmit(this.onSubmit.bind(this))}>
                        <Modal.Header closeButton>
                            <Modal.Title>Create Subject</Modal.Title>
                        </Modal.Header>
                        <Modal.Body>
                            {error &&
                            <Alert variant="danger">{error}</Alert>
                            }
                            <Form.Row>
                                <Form.Group as={Col} md="6">
                                <Field name="personFirstName" required component={FieldRBInput} label="First Name"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="personLastName" required component={FieldRBInput} label="Last Name"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="socialSecurityNumber" required component={FieldRBMaskedInput}
                                   mask="999-99-9999" label="Social Security Number (SSN)"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="personBirthDate" component={FieldRBDatePicker}
                                   label="Date of Birth"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="emailAddress" required component={FieldRBInput} label="Email Address"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="cityOfBirth" component={FieldRBInput} label="Birth City"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="stateOfBirth" component={FieldRBInput} label="Birth State"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="countryOfBirth" component={FieldRBCountrySelector}
                                   label="Birth Country"/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="gender" label="Gender" component={FieldRBSelect}
                                   options={Array.of('Male', 'Female')}/>
                                </Form.Group>

                                <Form.Group as={Col} md="6">
                                <Field name="maritalStatusCode" label="Marital Status" component={FieldRBSelect}
                                   options={Array.of('Single', 'Married')}/>
                                </Form.Group>
                            </Form.Row>
                            
                            {/*<h4>Security</h4>*/}
                            {/*<Field name="briefingLevel" component={FieldRBSelectWithKeys}*/}
                            {/*       label="Briefing Level" options={this.state.briefingLevels}/>*/}


                        </Modal.Body>
                        <Modal.Footer>
                            <Button onClick={this.close}>Cancel</Button>
                            <Button variant="primary" type="submit">Create</Button>
                        </Modal.Footer>
                    </form>
                </Modal>
            </Fragment>
        )
    }
}

SubjectNewModal.propTypes = {
    buttonText: PropTypes.string,
    subjectPrefill: PropTypes.shape({
        ssn: PropTypes.string
    }),
    onCreate: PropTypes.func.isRequired,
};

function validate(values) {
    const errors = {
    };
    // validate
    if (!values.personFirstName) {
        errors.personFirstName = "Enter a Person First Name";
    }
    if (!values.personLastName) {
        errors.personLastName = "Enter a Person Last Name";
    }
    // if (!values.personBirthDate) {
    //     errors.personBirthDate = "Enter a Date of Birth";
    // }
    if (!values.emailAddress) {
        errors.emailAddress = "Enter email address";
    } else if (!EMAIL_VALIDATION_STR.test(values.emailAddress)) {
        errors.emailAddress = "Enter a valid email address";
    }
    // if (!values.countryOfBirth) {
    //     errors.countryOfBirth = "Enter Country of Birth";
    // }
    if (!values.socialSecurityNumber) {
        errors.socialSecurityNumber = "SSN is required";
    } else if (!/^[0-9]{3}-[0-9]{2}-[0-9]{4}$/.test(values.socialSecurityNumber)) {
        errors.socialSecurityNumber = "Enter a valid SSN";
    }

    // if the errors is empty all is well
    return errors;
}

function mapStateToProps(state) {
    const formValues = state.form.SubjectNewForm;
    let selCountry = "";
    if (formValues.values && formValues.values.country) {
        selCountry = formValues.values.country;
    }
    return {
        sel_country: selCountry,
    }
}

export default reduxForm({
    validate, // same as validate:validate
    initialValues: {
        gender: 'Male',
        maritalStatusCode: 'Single',
        country: 'US'
    },
    form: 'SubjectNewForm'
})(
    connect(mapStateToProps, {createPerson, fetchOrgContracts, showSuccessGrowl, showErrorGrowl})(SubjectNewModal)
);